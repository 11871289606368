import { MenuItem } from 'src/app/shared/models/menu-item';

export const MENU_ITEMS: MenuItem[] = [
  {
    name: 'General',
    disabled: false,
    count: 0,
    param: 'general',
    text: 'Setup your basic infos and languages\n you speak fluently.',
    keepPosition: true,
  },
  {
    name: 'My Topics',
    disabled: false,
    count: 0,
    param: 'skills',
    text: 'Select topics you are knowledgeable or interested in.'
  },
  {
    name: 'Finances',
    disabled: false,
    count: 0,
    param: 'finance',
    text: 'Setup & see afterwards all your financial transactions. It is required by law to seperately register your payments (as a Connection) and payouts (as an Connector) on Getme.Global.'
  },
  {
    name: 'Scheduling',
    disabled: false,
    count: 0,
    param: 'scheduling',
    text: 'Setup times and conditions at which \n' +
      ' Connections can schedule meetings with you.'
  },
  {
    name: 'Notifications',
    disabled: false,
    count: 0,
    param: 'notifications',
    text: 'Setup precisely when we should <br>' +
      'inform you and through which channel.'
  },
  // {
  //   name: 'Visibility',
  //   disabled: false,
  //   count: 0,
  //   param: 'visibility',
  //   text: 'Adjust where and at what point your\n' +
  //     'projects will be displayed to others.'
  // },
  {
    name: 'Privacy',
    disabled: false,
    count: 0,
    param: 'privacy',
    text: 'See all data we track about you in one single panel. Decide on <br> your own which data you feel comfortable to share and which not.'
  }
];
