<div class="avpresentation">
  <div class="avpresentation__wave">
    <div #waveformRef></div>

    <app-curves [file]="file"
                [avRatingParams]="avRatingParams"
                [duration]="duration"
                [playerExists]="!!wavesurfer"
                [toggleTimer$]="toggleTimer$"
                [filesFeedback$]="filesFeedback$"
                [mediaFileIsOver$]="mediaFileIsOver$"
                (seekTo)="seekTo($event)"></app-curves>
  </div>

  <div class="avpresentation__rec-controls">
    <div class="avpresentation__track-controls">
      <div class="avpresentation__track-controls--track">
        <p class="current-time">
          {{ currentTime | hms }}
        </p>

        <div class="track-line">
          <div class="track-line-progress" [style.width]="trackTimeWidth"></div>
        </div>

        <p class="track-time">
          {{ (duration - currentTime) | hms }}
        </p>
      </div>
    </div>
  </div>
</div>
