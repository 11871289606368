<ng-container *ngIf="isSystemMessage && !(message | isPreviewIcebreaker: currentUser); else regularAvatar">
  <img src="assets/conversations/robot.svg" class="user-avatar"
       [ngClass]="{'user-avatar__author': isAuthor}"
       alt="Robot avatar">
</ng-container>
<ng-template #regularAvatar>
  <section class="user-avatar" [ngClass]="{'user-avatar__author': isAuthor}">
    <ng-container *ngIf="isAuthor && canReply && !(feedback && !feedbackQuestion && isRater) && !message.special">
      <button class="reply-btn"
              (click)="showReplyMenu = !showReplyMenu"
              cdkOverlayOrigin
              #trigger="cdkOverlayOrigin">
        <mat-icon>more_vert</mat-icon>
      </button>
      <ng-template
        cdkConnectedOverlay
        cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
        (detach)="showReplyMenu = false"
        (backdropClick)="showReplyMenu = false"
        [cdkConnectedOverlayPositions]="positions"
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayOpen]="showReplyMenu">
        <section class="reply-menu reply-menu__author">
          <section class="actions-list">
            <button class="action-btn" (click)="reply.emit(); showReplyMenu = false">
              <img src="assets/icons/reply.svg" alt="Reply icon">
              <span>Reply</span>
            </button>
            <button *ngIf="canEdit" (click)="editMessage(); showReplyMenu = false" class="action-btn">
              <img height="13" src="assets/ice-breaker/round-arrow.svg" alt="Rounded arrow icon">
              Edit Original
            </button>
          </section>
          <button class="btn-close">
            <mat-icon>more_vert</mat-icon>
          </button>
        </section>
      </ng-template>
    </ng-container>
    <img *ngIf="message.user" [src]="message.user | userAvatar"
         alt="User avatar">
    <ng-container *ngIf="!isAuthor && canReply && !(feedback && !feedbackQuestion && isRater) && !message.special">
      <button class="reply-btn"
              (click)="showReplyMenu = !showReplyMenu"
              cdkOverlayOrigin
              #trigger="cdkOverlayOrigin">
        <mat-icon>more_vert</mat-icon>
      </button>
      <ng-template
        cdkConnectedOverlay
        cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
        (detach)="showReplyMenu = false"
        (backdropClick)="showReplyMenu = false"
        [cdkConnectedOverlayPositions]="recipientPositionsReplyMenu"
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayOpen]="showReplyMenu">
        <section class="reply-menu reply-menu__recipient">
          <button class="btn-close btn-close__recipient">
            <mat-icon>more_vert</mat-icon>
          </button>
          <button class="action-btn" (click)="reply.emit(); showReplyMenu = false">
            <img src="assets/icons/reply.svg" alt="Reply icon">
            <span>Reply</span>
          </button>
<!--          <section class="actions-list">-->
<!--            <button class="action-btn" (click)="reply.emit(); showReplyMenu = false">-->
<!--              <img src="assets/icons/reply.svg" alt="Reply icon">-->
<!--              <span>Reply</span>-->
<!--            </button>-->
<!--            <button *ngIf="canEdit" (click)="editMessage(); showReplyMenu = false" class="action-btn">-->
<!--              <img height="13" src="assets/ice-breaker/round-arrow.svg" alt="Rounded arrow icon">-->
<!--              Edit Original-->
<!--            </button>-->
<!--          </section>-->
        </section>
      </ng-template>
    </ng-container>
  </section>
</ng-template>
<div class="message__container">
  <div class="message__container-inner"
       [ngClass]="{
        'message__container-inner--unread': !message.read,
        'message__container-inner--red': isRed,
        'message__container-inner--green': isGreen,
        'message__container-inner--yellow': isYellow,
        'message__container-inner--black': paymentSessionId,
        'cursor-pointer': !message.audio_message && !hasLinks,
        'overflow-hidden': feedback && !feedbackQuestion
      }">

    <div class="message__feedback" [class]="'message__feedback--' + feedback.avratingparam?.color"
         *ngIf="feedback && !feedbackQuestion">
      <p class="message__feedback-header">Initial comment</p>
      <div class="message__feedback-find">
        <button (click)="find.emit(feedback)">
          <img src="/assets/conversations/find-message.svg">
        </button>
        <p>find</p>
      </div>
      <p class="message__feedback-text" *ngIf="feedback.text">{{ feedback.text }}</p>
      <div class="message__feedback-audio" [ngClass]="{'mt-1': !feedback.text}">
        <app-audio-message
          *ngIf="feedback.audio_message"
          [audioMessage]="feedback.audio_message"
          [canSelectLanguage]="false"
        ></app-audio-message>
      </div>
    </div>

    <div class="message__initial-message" *ngIf="feedbackQuestion && !initialMessage"
         (click)="scrollToInitialMessage.emit()">
      <span class="message__initial-message-header">Initial question</span>
      <span>{{ feedbackQuestion.body }}</span>
    </div>

    <div class="message__initial-message" *ngIf="!feedbackQuestion && initialMessage"
         (click)="scrollToInitialMessage.emit()">
      <span class="message__initial-message-header">Initial message</span>
      <span class="message__initial-message-text">
        <ng-container *ngIf="initialMessage.body;">
          <span [innerHTML]="initialMessage.body | safe: 'html'"></span>
        </ng-container>
        <ng-template #noBodyTpl>
          <ng-container *ngIf="initialMessage.audio_message">Audio message</ng-container>
          <ng-container *ngIf="initialMessage.message_screenshots?.length">Image</ng-container>
        </ng-template>
      </span>
    </div>

    <ng-container *ngIf="isLinkMessage; else regularMessage">
      <div class="embed-container" *ngIf="message.body | getIframe | async as iframe">
        <app-iframely-embed [iframe]="$any(iframe)"></app-iframely-embed>
        <h2 class="message__text pb-10">{{icebreakerTitle}}</h2>
      </div>
    </ng-container>

    <ng-template #regularMessage>
      <h2 class="message__text"
          *ngIf="messageText"
          [ngClass]="{
          'pt-8': feedback && !feedbackQuestion,
          'pb-16': !message.audio_message,
          'pb-10': feedback && !feedbackQuestion && isRater || message.audio_message
        }">

        <p class="message__question" *ngIf="feedback && !feedbackQuestion">Question</p>
        <ng-container *ngIf="youtubeEmbedVideoId; else regularText">
          <div [innerHTML]="messageText | safe: 'html'"></div>
          <youtube-player
            [id]="'player' + youtubeEmbedVideoId"
            [videoId]="youtubeEmbedVideoId"
            [startSeconds]="youtubeStartTime"
            height="100%"
            width="100%">
          </youtube-player>
        </ng-container>
        <ng-template #regularText>
          <span [innerHTML]="messageText | safe: 'html'"></span>
        </ng-template>
      </h2>
    </ng-template>

    <img class="message__screenshot" *ngIf="message.message_screenshots?.length"
         [src]="message.message_screenshots[0].url" alt="">

    <div *ngIf="message.audio_message as audioMsg" class="message__audio-container"
         [ngClass]="{'pt-16': !message.body}">
      <app-audio-message
        [audioMessage]="audioMsg"
        [canSelectLanguage]="isAuthor || recipient?.id === currentUser.id"
        [userLanguages]="recipient?.lang"
        [paymentRequestAmount]="message.user?.id !== currentUser.id && !message.paymentrequestapproved && message.paymentrequestamount"
        (selectLang)="onAudioMsgLangChange($event)"
      ></app-audio-message>
    </div>

    <a class="message__feedback-answer"
       [routerLink]="'/rateflow/' + message.body"
       *ngIf="message.special === 'paid_feedback_request'">
      Go to Give Feedback
    </a>

    <button class="message__feedback-answer"
            *ngIf="feedback && !feedbackQuestion && isRater"
            (click)="reply.emit()">
      Answer
    </button>

    <button class="message__feedback-answer"
            *ngIf="allowCopyMessage"
            (click)="copyMessage()">
      {{ copied ? 'Copied!' : 'Copy message'}}
    </button>
  </div>

  <p class="message__info" *ngIf="message.created_at">
    {{ message.created_at | amLocal | amTimeAgo }}
  </p>
</div>
