import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-small-spinner',
  templateUrl: './small-spinner.component.html',
  styleUrls: ['./small-spinner.component.scss'],
})
export class SmallSpinnerComponent {
  @Input() diameter = 20;
  @Input() isWhite: boolean;
}
