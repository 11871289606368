export const avatarsImages: string[] = [
  `/assets/avatars/1.svg`,
  `/assets/avatars/2.svg`,
  `/assets/avatars/3.svg`,
  `/assets/avatars/4.svg`,
  `/assets/avatars/5.svg`,
  `/assets/avatars/6.svg`,
  `/assets/avatars/7.svg`,
  `/assets/avatars/8.svg`,
  `/assets/avatars/9.svg`,
  // `/assets/avatars/12.jpg`,
  // `/assets/avatars/13.jpg`,
  // `/assets/avatars/14.jpg`,
  // `/assets/avatars/15.jpg`,
  // `/assets/avatars/16.jpg`,
  // `/assets/avatars/17.jpg`,
  // `/assets/avatars/18.jpg`,
];
