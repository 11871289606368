<div class="flex-container">
  <div class="unit">
    <div class="heart">
        <div class="heart__piece"></div>
        <div class="heart__piece"></div>
        <div class="heart__piece"></div>
        <div class="heart__piece"></div>
        <div class="heart__piece"></div>
        <div class="heart__piece"></div>
        <div class="heart__piece"></div>
        <div class="heart__piece"></div>
        <div class="heart__piece"></div>
    </div>
    <p *ngIf="text">{{ text }}</p>
  </div>
</div>