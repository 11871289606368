import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessagesService } from 'src/app/services/messages.service';
import { ProjectService } from 'src/app/services/project.service';
import { User } from 'src/app/shared/models/user.model';
import { Message } from '../../models/message.model';
import { NewsfeedFeedback } from '../../models/newsfeedfeedback.model';
import { BaseComponent } from '../base.component';
import { ConnectedPosition, ConnectionPositionPair } from '@angular/cdk/overlay';
import { isUrlString } from '../../functions/is-url-string';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent extends BaseComponent implements OnInit {
  @ViewChild('audioRef') audioRef: ElementRef;

  @Input() private isAdvisorsPage: boolean;

  @Input() public message: Message;
  @Input() public currentUser: User = {};
  @Input() public isRater: boolean;
  @Input() public paymentSessionId: number;
  @Input() public canReply: boolean;
  @Input() public canEdit: boolean;

  @Input() public feedback: NewsfeedFeedback;
  @Input() public feedbackQuestion: Message;
  @Input() public allowCopyMessage: boolean;
  @Input() public initialMessage: Message;
  @Input() icebreakerTitle = '';

  @Input() recipient: User;
  @Input() type: 'message' | 'pqa' = 'message';
  @Input() pqaId: number;
  @Input() isSystemMessage = false;

  @Output() public find = new EventEmitter<NewsfeedFeedback>();
  @Output() public reply = new EventEmitter<void>();
  @Output() public edit = new EventEmitter<number>();
  @Output() public scrollToInitialMessage = new EventEmitter<void>();

  public copied: boolean;
  public messageText: string;

  public isAuthor: boolean;

  public isRed: boolean;
  public isGreen: boolean;
  public isYellow: boolean;

  public hasLinks: boolean;
  public showReplyBtn: boolean;
  youtubeEmbedVideoId = '';
  youtubeStartTime = 0;
  isLinkMessage = false;

  showReplyMenu = false;

  readonly positions: ConnectedPosition[] =
    [new ConnectionPositionPair({originX: 'end', originY: 'center'},
      {overlayX: 'end', overlayY: 'center'})
    ];

  // readonly recipientPositionsReplyMenu = [
  //   new ConnectionPositionPair({originX: 'start', originY: 'bottom'},
  //     {overlayX: 'start', overlayY: 'center'})
  // ];
  readonly recipientPositionsReplyMenu = [
    new ConnectionPositionPair({originX: 'start', originY: 'center'},
      {overlayX: 'start', overlayY: 'center'})
  ];

  constructor(
    private messagesService: MessagesService,
    private projectService: ProjectService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isAuthor = this.message?.user?.id && this.message.user.id === this.currentUser?.id;
    this.isRed = ['change_rate', 'meeting_denied', 'payment_denied'].includes(this.message.special);
    this.isGreen = ['meeting_accepted', 'payment_accepted'].includes(this.message.special);
    this.isYellow = ['paid_feedback_request', 'meeting_request'].includes(this.message.special);

    this.message.body = this.message.body || '';

    switch (this.message.special) {
      case 'change_rate':
        this.messageText = this.isAuthor ?
          `You adjusted the hourly rate to ${this.message.body} for this creative` :
          `Connector adjusted the hourly rate to ${this.message.body}`;
        break;

      case 'payment_accepted':
        this.messageText = this.isAuthor ? 'You accepted the payment' : 'Connection accepted the payment';
        break;

      case 'payment_denied':
        this.messageText = this.isAuthor ? 'You declined the payment' : 'Connection declined the payment';
        break;

      case 'meeting_accepted':
        this.messageText = this.isAuthor ? 'You accepted the meeting' : 'User accepted the meeting';
        break;

      case 'meeting_denied':
        if (this.isAdvisorsPage) {
          this.messageText = 'Request denied, please contact another advisor';
        } else {
          this.messageText = this.isAuthor ? 'You declined the meeting' : 'User declined the meeting';
        }
        break;

      case 'paid_feedback_request':
        this.messageText = 'Paid feedback request';
        break;

      case 'meeting_request':
        this.messageText = this.isAuthor ? 'You requested a meeting' : 'User requested a meeting';
        break;

      default:
        if (this.isSystemMessage && isUrlString(this.message.body)) {
          this.isLinkMessage = true;
        }
        this.messageText = this.message.body
          .replace(/(((https?:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/g, (match) => {
            const trimmed = match.length > 40 ? match.slice(0, 40) + '...' : match;
            this.hasLinks = true;
            this.youtubeEmbedVideoId = this.getYoutubeVideoId(match);
            if (this.youtubeEmbedVideoId) {
              this.youtubeStartTime = this.getYoutubeTime(match);
              return `<a href="${match}" title="${match}" target="_blank">${trimmed}</a>`;
            }
            return `<a href="${match}" title="${match}" target="_blank">${trimmed}</a>`;
          });
        break;
    }
  }

  public copyMessage() {
    this.messagesService.copiedMessage = this.message;
    this.copied = true;
  }

  public onAudioMsgLangChange(language: string): void {
    this.message.audio_message.language = language;

    let request$: Observable<unknown>;

    switch (this.type) {
      case 'message':
        request$ = this.messagesService.updateMessage(this.message.id, {audio_message: this.message.audio_message});
        break;
      case 'pqa':
        request$ = this.projectService.updatePQAnswer(this.pqaId, {audio_message: this.message.audio_message});
        break;
      default:
        throw new Error('Invalid type');
    }

    request$.pipe(
      takeUntil(this.destroyed)
    ).subscribe();
  }

  public deleteMessage() {
    this.messagesService.deleteMessage(this.message.id).subscribe();
  }

  editMessage(): void {
    this.edit.emit(this.message.icebreaker_question_id);
  }

  private getYoutubeVideoId(url: string): string {
    let id: Array<string>;
    const formattedUrl = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (formattedUrl[2]) {
      id = formattedUrl[2].split(/[^0-9a-z_\-]/i);
      return id[0];
    }

    return null;
  }

  private getYoutubeTime(url: string): number {
    const regex = /(\?t|&start)=(\d+)/;
    const match = url.match(regex);
    if (match) {
      return +match[2];
    }
    return 0;
  }
}
