import { Activityscore } from './activity_score.model';
import { Advisorscore } from './advisor_score.model';
import { UserSkill } from './UserSkill.model';
import { Advisordate } from './Advisordate.model';
import { UserWeblink } from './user-weblink.model';
import { Ruleset } from './ruleset';
import { IceBreaker } from '../../pages/profile/ice-breaker-template/ice-breaker-template-messages';

export class User {
  constructor(
    public email?: string,
    public id?: number,
    public jitsiId?: number,
    public admin?: boolean,
    public referred_by?: number,
    public referral_token?: string,
    public image?: string,
    public imagethumb?: string,
    public project_ids?: number[],
    public city?: string,
    public hours?: string,
    public days?: string,
    public name?: string,
    public username?: string,
    public time_zone?: string,
    public isteam?: boolean,
    public website?: string,
    // public balance?: number,
    public showimage?: boolean,
    public showcity?: boolean,

    public sharetoken?: string,
    public showname?: boolean,
    public showwebsite?: boolean,
    public ratescore?: number,
    public stripe_user_id?: string,
    public stripe_customer_id?: string,
    public advisorrate?: number,
    public user_skills?: UserSkill[],
    public advisordates?: Advisordate[],
    public skills?: string[],
    public adviserIDs?: number[],
    public activity_score?: Activityscore,
    public advisor_score?: Advisorscore,
    public lang?: string,

    public profileimage?: string,

    public showtutorials?: boolean,
    public funnelColor?: string,
    public queuedprojects?: number[],
    public open_cards?: boolean,
    public inspiring_rates_count?: number,

    public online?: boolean,
    public pinned?: boolean,
    public user_weblinks?: UserWeblink[],
    public video_chat_allowed?: boolean,
    public live_chat_allowed?: boolean,
    public appointments_auto_confirmation?: boolean,

    public rulesets?: Ruleset[],
    public pinned_advisors?: number[],
    public about?: AboutMe | null,
    public icebreakers?: IceBreaker[]
  ) { }
}

export interface AboutMe {
  title: string;
  description: string;
}
