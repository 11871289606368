import { Week } from './models/week';

export const RESORT_TITLE = 'Why do you think it\'s better this way for this project?';
export const COMMENTS_TITLE = 'What are your general comments on this project?';
export const STRENGTHS_TITLE = 'What are the strengths of the entire project?';
export const WEAKNESSES_TITLE = 'What are the weaknesses of the entire project?';
export const NEXTSTEPS_TITLE = 'What next actions could improve this entire project?';
export const LINKS_TITLE = 'What links could be helpful references for this project?';

export const RESORT_TITLE_FILE = 'Why do you think it\'s better this way for this file?';
export const COMMENTS_TITLE_FILE = 'What are your general comments on this file?';
export const STRENGTHS_TITLE_FILE = 'What are the strengths of this file?';
export const WEAKNESSES_TITLE_FILE = 'What are the weaknesses of this file?';
export const NEXTSTEPS_TITLE_FILE = 'What next actions could improve this file?';
export const LINKS_TITLE_FILE = 'What links could be helpful references for this file?';

export const RESORT_TITLE_CONCEPT = 'Why do you think it\'s better this way for this concept?';
export const COMMENTS_TITLE_CONCEPT = 'What are your general comments on this concept?';
export const STRENGTHS_TITLE_CONCEPT = 'What are the strengths of this concept?';
export const WEAKNESSES_TITLE_CONCEPT = 'What are the weaknesses of this concept?';
export const NEXTSTEPS_TITLE_CONCEPT = 'What next actions could improve this concept?';
export const LINKS_TITLE_CONCEPT = 'What links could be helpful references for this concept?';
export const IS_CREATED_ICE_BREAKER_BEFORE = 'CREATE_ICE_BREAKER_BEFORE';

export const SKIP_PROJECT = -1;

export const DaysWeek: Week[] = [
  {
    name: 'MO',
    status: false
  },
  {
    name: 'TU',
    status: false
  },
  {
    name: 'WE',
    status: false
  },
  {
    name: 'TH',
    status: false
  },
  {
    name: 'FR',
    status: false
  },
  {
    name: 'SA',
    status: false
  },
  {
    name: 'SU',
    status: false
  }
];

export const NOT_LISTED_TEXT = 'not listed';
