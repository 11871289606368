import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../shared/models/user.model';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AnalyticsService } from '../services/analytics.service';
import { API_URL, AUTH_URL, PASSWORD_URL, REGISTER_REFERRAL_URL, RESEND_CONFIRMATION_URL, SIGN_IN_URL, VALIDATE_TOKEN_URL } from 'src/config/config';
import { Ruleset } from '../shared/models/ruleset';
import { Scheduling } from '../pages/usersettings/interfaces/scheduling';

export interface AuthResponseData {
  id: number;
  email: string;
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userId: number;
  userSubject$ = new BehaviorSubject<User>(null);
  userProfileInfo$ = new BehaviorSubject<User>(null);

  constructor(
    private analyticsService: AnalyticsService,
    private http: HttpClient,
    private router: Router,
  ) { }

  resetConfirmPassword(newpass: string) {
    return this.http.put(PASSWORD_URL, {
      password: newpass,
      password_confirmation: newpass
    });
  }

  requestConfirmationEmail(email: string) {
    return this.http.post<User>(RESEND_CONFIRMATION_URL, { email });
  }

  resetPassword(email: string) {
    return this.http.post(PASSWORD_URL, {
      email,
      redirect_url: 'https://getme.global/auth?mode=login'
    });
  }

  fetchUserForProfileToken(token: string) {
    const url = API_URL + 'users?sharetoken=' + token;

    return this.http.get<User>(url).pipe(
      map(user => {
        this.userProfileInfo$.next(user);
        return user;
      })
    );
  }

  fetchUserByEmail(email: string) {
    const url = API_URL + 'userByEmail?email=' + email;

    return this.http.get<User>(url);
  }

  fetchUser(userId: number) {
    const url = API_URL + 'users/' + userId;
    return this.http.get<User>(url);
  }

  login(email: string, password: string) {
    const body = { email, password };

    return this.http.post(SIGN_IN_URL, body, { observe: 'response' }).pipe(
      tap((res: HttpResponse<any>) => this.setHeaders(res.headers)),
      map((res: HttpResponse<any>) => res.body),
      map((res: { data: User }) => res.data),
      switchMap((res: User) => this.fetchUser(res.id)),
      tap((user: User) => this.userSubject$.next(user)),
    );
  }

  setHeaders(headers: HttpHeaders) {
    localStorage.setItem('accessToken', headers.get('access-token'));
    localStorage.setItem('client', headers.get('client'));
    localStorage.setItem('expiry', headers.get('expiry'));
    localStorage.setItem('tokenType', headers.get('token_type'));
    localStorage.setItem('uid', headers.get('uid'));
  }

  signUp(username: string, email: string, password: string, isteam?: boolean) {
    return this.http.post(AUTH_URL, { username, email, password, isteam }, { observe: 'response' }).pipe(
      tap((res: HttpResponse<any>) => this.setHeaders(res.headers)),
      map((res: HttpResponse<any>) => res.body),
      map((res: { data: User }) => res.data),
      switchMap((res: User) => this.fetchUser(res.id)),
      tap(res => this.userSubject$.next(res)),
    );
  }

  registerReferral(userId: number, referredByToken: string) {
    return this.http.post<User>(REGISTER_REFERRAL_URL, {
      userId, referredByToken
    });
  }

  updateUser(user: User) {
    const url = API_URL + 'users/' + user.id;

    return this.http.put<User>(url, { user }).pipe(
      tap(res => this.userSubject$.next(res))
    );
  }

  autoLogin() {
    if (!this.userIsSignedIn()) {
      return;
    }

    this.http.get(VALIDATE_TOKEN_URL).pipe(
      map((res: { data: User }) => res.data),
      mergeMap((res: User) => this.fetchUser(res.id)),
      tap((res: User) => this.userSubject$.next(res))
    ).subscribe();
  }

  logout() {
    this.userSubject$.next(null);

    localStorage.removeItem('accessToken');
    localStorage.removeItem('client');
    localStorage.removeItem('expiry');
    localStorage.removeItem('tokenType');
    localStorage.removeItem('uid');

    this.router.navigate(['/home']);
  }

  userIsSignedIn() {
    return !!localStorage.getItem('accessToken');
  }

  isAnon(userId) {
    return !userId || userId === 156;
  }

  createScheduling(data: Ruleset) {
    const url = API_URL + 'rulesets';
    return this.http.post<Ruleset>(url, data);
  }

  getListScheduling() {
    const url = API_URL + `users/scheduling`;
    return this.http.get<Scheduling>(url);
  }

  deleteItemScheduling(id: number) {
    const url = API_URL + `rulesets/${id}`;
    return this.http.delete(url);
  }
}
