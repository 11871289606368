import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { AudioRecordingService } from 'src/app/services/audio-recorder';
import { ANON_USER_ID, COMPANION_URL } from 'src/config/config';
import { Uppy } from '@uppy/core';
import { AwsS3 } from 'uppy';
import { User } from '../../models/user.model';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-audio-record',
  templateUrl: './audio-record.component.html',
  styleUrls: ['./audio-record.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AudioRecordingService]
})
export class AudioRecordComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('messageInput') private messageInput: ElementRef;
  @ViewChild('marqueeRef') private marqueeRef: ElementRef;

  @Input() public user: User;
  @Input() public messageTitle: string;
  @Input() public sendBtnSrc = 'assets/icons/send.svg';
  @Input() public isFlexFeedback = false;
  @Input() private audioError$: Observable<boolean>;
  @Input() private stopRecording$: Observable<void>;

  @Output() private uploadingFinished = new EventEmitter<{}>();
  @Output() private error = new EventEmitter<void>();
  @Output() private close = new EventEmitter<void>();

  public recordedTime$ = new BehaviorSubject<number>(0);
  public isRecordingPaused: boolean;
  public isRecordingStopped: boolean;
  public isAudioMessageUploading: boolean;

  private audioMessageTitle: string;

  private uploader = new Uppy({ id: 'audiomessage-uploader' }).use(AwsS3, {
    companionUrl: COMPANION_URL,
    metaFields: ['folder',],
  });

  private withoutTitle: boolean;
  private isMobile: boolean;

  public showAnimation: boolean;
  public textPlaceholder: string;

  public get isStopped(): boolean {
    return this.isRecordingStopped || this.isAudioMessageUploading;
  }

  public get isCollapsed(): boolean {
    return this.isFlexFeedback || this.isMobile;
  }

  public get inputTitle(): string {
    return this.isCollapsed ? 'Add title...' : 'Write title here';
  }

  constructor(
    private audioRecordingService: AudioRecordingService,
    private authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.startAudioRecord();

    this.audioError$?.pipe(
      takeUntil(this.destroyed),
      filter(res => res),
      tap(() => {
        this.showAnimation = true;
        this.textPlaceholder = 'Error while uploading audio';
      })
    ).subscribe();

    this.breakpointObserver.observe('(max-width: 500px)').pipe(
      takeUntil(this.destroyed),
      tap(state => this.isMobile = state.matches)
    ).subscribe();

    this.stopRecording$?.pipe(
      takeUntil(this.destroyed),
      tap(() => {
        this.getAudioMessageTitle();
        this.audioRecordingService.stopRecording();
      })
    ).subscribe();
  }

  onInputFocus() {
    const value = this.messageInput.nativeElement.value;

    this.messageInput.nativeElement.value = value === this.inputTitle ? '' : value;
  }

  handleMarquee() {
    const marquee = this.marqueeRef.nativeElement;
    const speed = 1;

    const container: HTMLElement = marquee.querySelector('.inner');
    const content: HTMLElement = marquee.querySelector('.inner > *');
    const elWidth = content.offsetWidth;
    const clone = content.cloneNode(true);
    container.appendChild(clone);
    let progress = 1;

    function loop() {
      progress = progress - speed;

      if(progress <= elWidth * -1) {
        progress = 0;
      }

      container.style.transform = 'translateX(' + progress + 'px)';
      container.style.transform += ' skewX(' + speed * 0.4 + 'deg)';
      window.requestAnimationFrame(loop);
    }

    loop();
  }

  startAudioRecord() {
    this.showAnimation = true;
    this.textPlaceholder = this.user.lang ? `Talk in: ${this.user.lang.split(',').join(', ') } ` : 'Talk in English';
    this.isRecordingPaused = false;
    this.audioRecordingService.startRecording();

    setTimeout(() => {
      const messagePlaceholderElement: HTMLElement = this.marqueeRef.nativeElement;
      const isOverflown = messagePlaceholderElement.scrollWidth > messagePlaceholderElement.clientWidth;
      if (isOverflown) {
        this.handleMarquee();
      }
    });

    forkJoin([
      this.audioRecordingService.recordingFail$.pipe(tap(() => this.error.emit())),
      this.audioRecordingService.recordedTime$.pipe(tap(time => this.recordedTime$.next(time))),
      this.audioRecordingService.recordedBlob$.pipe(
        tap(data => this.uploadAudioMessage(data.blob, data.title, data.duration))
      )
    ]).pipe(takeUntil(this.destroyed)).subscribe();
  }

  public deleteRecording(close = false) {
    this.audioRecordingService.abortRecording();

    if (close) {
      this.close.emit();
    }
  }

  private async uploadAudioMessage(blob: Blob, title: string, duration: number) {
    const uploadedFile = await this.useUploader(blob, title);

    const languages = this.audioRecordingService.createArrayOfAvailableLanguages(this.user.lang);

    this.uploadingFinished.emit({
      title: this.audioMessageTitle,
      url: decodeURIComponent(uploadedFile.successful[0].uploadURL),
      duration,
      language: this.audioRecordingService.getLastUsedLanguage(languages),
    });
  }

  private async useUploader(blob: Blob, title: string) {
    this.uploader.addFile({
      data: blob,
      name: title,
      source: 'file input',
      type: blob.type,
    });
    const userId = this.authService.userSubject$.value?.id || ANON_USER_ID;
    this.uploader.setMeta({ folder: `users/${userId}/audio-messages` });

    const uploadedFile = await this.uploader.upload();
    this.uploader.reset();
    return uploadedFile;
  }

  public stopAudioRecord(withoutTitle = false) {
    if (this.isAudioMessageUploading) {
      return;
    }

    if (this.isRecordingStopped || this.isFlexFeedback) {
      this.withoutTitle = withoutTitle;
      this.isRecordingPaused = false;
      this.isAudioMessageUploading = true;
      this.getAudioMessageTitle();

      this.audioRecordingService.stopRecording();
      return;
    }

    this.pauseAudioRecord();
    this.showAudioMessageTitle();
  }

  public pauseAudioRecord() {
    this.isRecordingPaused = true;
    this.showAnimation = false;
    this.textPlaceholder = 'Paused';
    this.audioRecordingService.pauseRecording();
  }

  private setInputPlaceholderAnimation() {
    let dots = '';

    this.showAnimation = false;
    this.textPlaceholder = 'Uploading audio';

    setInterval(() => {
      if (dots === '.') {
        dots = '..';
      } else if (dots === '..') {
        dots = '...';
      } else if (!dots || dots === '...') {
        dots = '.';
      }

      setTimeout(() => {
        this.textPlaceholder = 'Uploading audio' + dots;
      });
    }, 300);
  }

  private getAudioMessageTitle() {
    this.isAudioMessageUploading = true;

    if (this.messageInput) {
      this.messageInput.nativeElement.classList.remove('add-title');
      const text = this.messageInput.nativeElement.value;
      this.audioMessageTitle = text === this.inputTitle || this.withoutTitle ? '' : text;
    }

    this.isRecordingStopped = false;
    this.cdr.detectChanges();
    this.setInputPlaceholderAnimation();
  }

  private showAudioMessageTitle() {
    this.isRecordingStopped = true;
    this.audioMessageTitle = '';

    setTimeout(() => {
      this.messageInput.nativeElement.value = this.messageTitle || this.inputTitle;
      this.messageInput.nativeElement.classList.add('add-title');
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.deleteRecording();
  }
}
