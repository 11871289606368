<div class="footer">
  <div class="bubbles">
    <div class="bubble"
         style="--size:3.1772552454260046rem; --distance:8.192107003877886rem; --position:39.92492025625942%; --time:2.5606390563971115s; --delay:-2.3248243395854846s;"></div>
    <div class="bubble"
         style="--size:4.394504054328749rem; --distance:9.2634598620974rem; --position:81.62677946138132%; --time:3.6330699401962185s; --delay:-2.339194107773388s;"></div>
    <div class="bubble"
         style="--size:3.05239521194624rem; --distance:9.224507165014993rem; --position:95.05209967732233%; --time:2.0181689605383992s; --delay:-2.5465225423627458s;"></div>
    <div class="bubble"
         style="--size:4.9137997587439814rem; --distance:6.456666891854028rem; --position:95.47305271234414%; --time:2.625317488695577s; --delay:-2.0713357189660013s;"></div>
    <div class="bubble"
         style="--size:4.167401707945632rem; --distance:6.490855986233216rem; --position:17.721701775042295%; --time:3.6405944772628835s; --delay:-2.0182087191316462s;"></div>
    <div class="bubble"
         style="--size:4.068569346864457rem; --distance:8.420876556718166rem; --position:95.52319244658811%; --time:2.00714455913252s; --delay:-2.424635379815189s;"></div>
    <div class="bubble"
         style="--size:5.188088407229542rem; --distance:8.639168416583287rem; --position:57.65578810632772%; --time:2.839427309384651s; --delay:-2.4846066875783994s;"></div>
    <div class="bubble"
         style="--size:4.4342440641430585rem; --distance:9.940772701003777rem; --position:-0.693061678748701%; --time:3.710279506494475s; --delay:-2.707512745983448s;"></div>
    <div class="bubble"
         style="--size:2.4746015634893954rem; --distance:8.782766801835454rem; --position:48.05586129288612%; --time:2.239853344692296s; --delay:-2.696788844632104s;"></div>
    <div class="bubble"
         style="--size:4.297997393773689rem; --distance:8.984548310055743rem; --position:43.05260459640425%; --time:3.9673618362734007s; --delay:-2.6526169251261065s;"></div>
    <div class="bubble"
         style="--size:5.725718152069419rem; --distance:6.476827925994215rem; --position:21.14042612512234%; --time:2.9635501736926426s; --delay:-3.6484626722542806s;"></div>
    <div class="bubble"
         style="--size:4.929937399581934rem; --distance:6.5622841333427555rem; --position:15.393000680940144%; --time:2.6534822873544646s; --delay:-3.3619205528079594s;"></div>
    <div class="bubble"
         style="--size:3.980125671807195rem; --distance:9.353696834846026rem; --position:83.37126895140014%; --time:3.8923573281637207s; --delay:-2.9571297812002566s;"></div>
    <div class="bubble"
         style="--size:4.626620353441051rem; --distance:9.735232467936493rem; --position:48.61423202816395%; --time:3.367790755684432s; --delay:-3.350544809405295s;"></div>
    <div class="bubble"
         style="--size:5.285198125157334rem; --distance:8.674762647027634rem; --position:84.5997396719846%; --time:3.4707467033730746s; --delay:-2.97890330993702s;"></div>
    <div class="bubble"
         style="--size:4.542591757522471rem; --distance:6.044013912120776rem; --position:48.06526352661981%; --time:3.077017292808721s; --delay:-2.8551837502243713s;"></div>
    <div class="bubble"
         style="--size:5.825953037368967rem; --distance:7.802753408983339rem; --position:89.00988103835117%; --time:3.2292311685088446s; --delay:-2.275234077725826s;"></div>
    <div class="bubble"
         style="--size:3.1036324366876444rem; --distance:7.392056577929497rem; --position:16.525539115443525%; --time:2.4967223001708447s; --delay:-2.795190669846632s;"></div>
    <div class="bubble"
         style="--size:2.9327833092448694rem; --distance:9.556431647206704rem; --position:18.14475801523124%; --time:2.3830625780754s; --delay:-2.626422722807843s;"></div>
    <div class="bubble"
         style="--size:2.797809753871185rem; --distance:7.463312742351886rem; --position:1.0872532689687926%; --time:2.612070111398669s; --delay:-2.358390433413206s;"></div>
    <div class="bubble"
         style="--size:4.312011689763573rem; --distance:7.2098932029257385rem; --position:97.8633977746858%; --time:3.3652291416265516s; --delay:-2.0677433697856054s;"></div>
    <div class="bubble"
         style="--size:3.4036598201044077rem; --distance:9.502513738984607rem; --position:38.00158326182971%; --time:2.146379372594054s; --delay:-3.6471945387038223s;"></div>
    <div class="bubble"
         style="--size:4.1835195046542974rem; --distance:6.580743352835776rem; --position:35.15603652562176%; --time:3.991834765920765s; --delay:-3.284789569631818s;"></div>
    <div class="bubble"
         style="--size:3.2621274185444937rem; --distance:7.034684521243924rem; --position:8.837807396002887%; --time:2.1297665709283375s; --delay:-2.0881786904102544s;"></div>
    <div class="bubble"
         style="--size:5.270958323978815rem; --distance:8.974121757364573rem; --position:98.39352352180055%; --time:2.56628728540006s; --delay:-2.420524728660841s;"></div>
    <div class="bubble"
         style="--size:3.244084928540919rem; --distance:8.91792810140577rem; --position:32.74961829544857%; --time:3.5374045760109736s; --delay:-3.6507802609108944s;"></div>
    <div class="bubble"
         style="--size:3.7898170445787374rem; --distance:6.431181301076826rem; --position:19.245221019679462%; --time:3.1392549743456932s; --delay:-2.581554148170866s;"></div>
    <div class="bubble"
         style="--size:3.7961180979669447rem; --distance:8.50217561540552rem; --position:55.99238048531273%; --time:2.503869874109506s; --delay:-2.1326470053395075s;"></div>
    <div class="bubble"
         style="--size:3.0785923404900197rem; --distance:8.72714137184387rem; --position:69.59055663588316%; --time:3.0404270945091385s; --delay:-3.0263237169911386s;"></div>
    <div class="bubble"
         style="--size:3.5578824985313853rem; --distance:6.92333643881495rem; --position:34.39869061948474%; --time:3.509928138823237s; --delay:-3.1843841200970653s;"></div>
    <div class="bubble"
         style="--size:2.904696671714725rem; --distance:7.4530482040827355rem; --position:0.16117276696972826%; --time:3.8181716727763475s; --delay:-3.149093739392884s;"></div>
    <div class="bubble"
         style="--size:3.7430363076385973rem; --distance:8.487657255826443rem; --position:22.50854563029558%; --time:3.256897806956556s; --delay:-2.667355706495196s;"></div>
    <div class="bubble"
         style="--size:3.4357474714842082rem; --distance:9.481720835640907rem; --position:102.74155077957141%; --time:2.9538620397898385s; --delay:-3.0055210591052632s;"></div>
    <div class="bubble"
         style="--size:2.408624113612519rem; --distance:7.2503551442028735rem; --position:25.040307853824267%; --time:3.453809425215897s; --delay:-3.9275784578842163s;"></div>
    <div class="bubble"
         style="--size:2.3582293284240894rem; --distance:8.176098069311546rem; --position:73.36475834993281%; --time:2.9557021329567403s; --delay:-2.0823432865959335s;"></div>
    <div class="bubble"
         style="--size:3.189410801515863rem; --distance:8.087014779790398rem; --position:98.42017704821889%; --time:2.246223310687106s; --delay:-2.6144162728983877s;"></div>
    <div class="bubble"
         style="--size:3.5766216326044704rem; --distance:7.288271174194588rem; --position:21.92694825446679%; --time:3.458723506362207s; --delay:-3.173581855525437s;"></div>
    <div class="bubble"
         style="--size:5.4352493125157935rem; --distance:8.567973095381515rem; --position:100.17967341564601%; --time:2.4320865644812737s; --delay:-3.313296895113165s;"></div>
    <div class="bubble"
         style="--size:5.4533740325194655rem; --distance:9.481007419227403rem; --position:20.952286404870303%; --time:2.9185530163754456s; --delay:-3.4288463583890274s;"></div>
    <div class="bubble"
         style="--size:5.407279538747089rem; --distance:9.323990211420293rem; --position:96.65620889712916%; --time:2.380713470816979s; --delay:-3.6986870814622783s;"></div>
    <div class="bubble"
         style="--size:4.2487403866726945rem; --distance:7.259203922143105rem; --position:13.281597576928839%; --time:3.2456326237216495s; --delay:-2.5883142936165626s;"></div>
    <div class="bubble"
         style="--size:4.932956903306856rem; --distance:8.603336495151606rem; --position:13.816441724781534%; --time:3.8222912290075137s; --delay:-2.22960421434758s;"></div>
    <div class="bubble"
         style="--size:4.955335276253718rem; --distance:8.947986656655221rem; --position:18.910811337719032%; --time:2.175138143258108s; --delay:-3.690741056246882s;"></div>
    <div class="bubble"
         style="--size:4.615232187085415rem; --distance:8.842010875861746rem; --position:56.22201888137875%; --time:3.105914047075148s; --delay:-3.4688002466783265s;"></div>
    <div class="bubble"
         style="--size:2.498663035026386rem; --distance:7.0205797603903495rem; --position:76.59736367409107%; --time:3.4409583255409326s; --delay:-2.732420288174056s;"></div>
    <div class="bubble"
         style="--size:3.5545494681241436rem; --distance:7.926857601065032rem; --position:92.08185044770198%; --time:3.8675378465160994s; --delay:-3.808932521787902s;"></div>
    <div class="bubble"
         style="--size:4.039757985701792rem; --distance:6.579941868996993rem; --position:36.23547038154569%; --time:3.8031062149912196s; --delay:-3.370151302174154s;"></div>
    <div class="bubble"
         style="--size:5.496671479925759rem; --distance:8.08987424784705rem; --position:0.20554625839026386%; --time:2.4711362622450217s; --delay:-2.460344414011582s;"></div>
    <div class="bubble"
         style="--size:3.4285971207998616rem; --distance:7.53881169105735rem; --position:44.495642406869145%; --time:3.9263469947982212s; --delay:-3.1330359174353872s;"></div>
    <div class="bubble"
         style="--size:3.8283529627830193rem; --distance:8.426069496666198rem; --position:18.22517906871244%; --time:3.578556633816009s; --delay:-2.1339444938582597s;"></div>
    <div class="bubble"
         style="--size:4.499457010739321rem; --distance:9.011140015709728rem; --position:9.820094563011576%; --time:2.5432578690889365s; --delay:-3.4870078206386936s;"></div>
    <div class="bubble"
         style="--size:3.3168347768388564rem; --distance:8.99938408638165rem; --position:72.92615661390897%; --time:2.227818987820644s; --delay:-2.563143223897319s;"></div>
    <div class="bubble"
         style="--size:4.854173130521776rem; --distance:7.249018522563405rem; --position:53.042594704403996%; --time:3.1410284889500337s; --delay:-2.000852604405662s;"></div>
    <div class="bubble"
         style="--size:2.2717549865114552rem; --distance:9.632090555589354rem; --position:77.79372153429932%; --time:3.107302104497003s; --delay:-3.1762382645254394s;"></div>
    <div class="bubble"
         style="--size:3.4589253783774376rem; --distance:7.411993297861439rem; --position:67.12328065797082%; --time:2.4135338751710704s; --delay:-2.8081443200575884s;"></div>
    <div class="bubble"
         style="--size:4.201395451065084rem; --distance:7.774900246451083rem; --position:24.977124854475846%; --time:2.186783585452648s; --delay:-2.729886784478626s;"></div>
    <div class="bubble"
         style="--size:4.945524673961383rem; --distance:9.194024170785367rem; --position:7.172574606474791%; --time:3.7162487614576283s; --delay:-3.0437079094204407s;"></div>
    <div class="bubble"
         style="--size:4.8312472906344075rem; --distance:7.618740244204432rem; --position:69.35786901127376%; --time:3.936735448845742s; --delay:-3.8557733813148136s;"></div>
    <div class="bubble"
         style="--size:3.189620655595567rem; --distance:7.39450405646201rem; --position:65.57761027959236%; --time:3.516129552792797s; --delay:-2.185819970067582s;"></div>
    <div class="bubble"
         style="--size:2.210533545595858rem; --distance:8.63061985918868rem; --position:44.02637392088985%; --time:3.2371919789337293s; --delay:-3.2731223839239436s;"></div>
    <div class="bubble"
         style="--size:4.034253952273817rem; --distance:8.00778943436367rem; --position:98.82373986423312%; --time:3.020865317344015s; --delay:-2.278281142305984s;"></div>
    <div class="bubble"
         style="--size:2.575452577100066rem; --distance:9.122184158501973rem; --position:74.98035053111983%; --time:3.0819098870822477s; --delay:-2.154659542797746s;"></div>
    <div class="bubble"
         style="--size:3.6616563572258816rem; --distance:7.081883066674863rem; --position:44.65500592291644%; --time:2.669283005500194s; --delay:-2.8479915360747428s;"></div>
    <div class="bubble"
         style="--size:5.465545603849451rem; --distance:8.827421675826422rem; --position:6.368759815584696%; --time:3.7831953267991483s; --delay:-2.0067906366459534s;"></div>
    <div class="bubble"
         style="--size:4.4054586832810365rem; --distance:8.109589156100057rem; --position:19.53593458551836%; --time:2.083610941343268s; --delay:-3.0031229740111423s;"></div>
    <div class="bubble"
         style="--size:2.9730285193348367rem; --distance:7.391785098611389rem; --position:103.83444383384214%; --time:3.926296549653277s; --delay:-2.304333252064994s;"></div>
    <div class="bubble"
         style="--size:3.449745440246711rem; --distance:9.574441729108896rem; --position:59.35698267000993%; --time:3.4887921359244607s; --delay:-3.7476558581884043s;"></div>
    <div class="bubble"
         style="--size:2.374089939190191rem; --distance:9.674391812494871rem; --position:33.46852672781257%; --time:3.6466551724957488s; --delay:-2.989848365950336s;"></div>
    <div class="bubble"
         style="--size:5.805192649355879rem; --distance:8.317955064370658rem; --position:96.3357615671316%; --time:2.439926929542144s; --delay:-2.4756784285233597s;"></div>
    <div class="bubble"
         style="--size:2.3304634420988792rem; --distance:8.87101489023161rem; --position:51.0550754244814%; --time:2.906824173263533s; --delay:-2.0368149157745s;"></div>
    <div class="bubble"
         style="--size:3.025892825888471rem; --distance:9.476249984266364rem; --position:18.005913876858152%; --time:2.3697764320704677s; --delay:-2.7398901622905383s;"></div>
    <div class="bubble"
         style="--size:3.481787292678283rem; --distance:8.668946107275634rem; --position:18.727262484138187%; --time:2.081079147322342s; --delay:-3.5874459654223174s;"></div>
    <div class="bubble"
         style="--size:3.9061750037291505rem; --distance:9.865581165376152rem; --position:26.059828452304075%; --time:3.2477567499570164s; --delay:-2.714183461461872s;"></div>
    <div class="bubble"
         style="--size:3.9797986641734813rem; --distance:6.728355428685362rem; --position:95.76821930110897%; --time:2.1853550076999206s; --delay:-3.444617574871442s;"></div>
    <div class="bubble"
         style="--size:5.390798312277564rem; --distance:8.162906608593955rem; --position:12.770170272654%; --time:2.750181856752749s; --delay:-3.8301660149448438s;"></div>
    <div class="bubble"
         style="--size:4.092399626378715rem; --distance:6.666733870289651rem; --position:30.08546346728083%; --time:3.467145145666441s; --delay:-3.006578068000972s;"></div>
    <div class="bubble"
         style="--size:4.873990798278552rem; --distance:9.782991776507798rem; --position:10.868636575919211%; --time:3.15447599181429s; --delay:-3.244212903467622s;"></div>
    <div class="bubble"
         style="--size:5.733170931080266rem; --distance:9.711033635188421rem; --position:17.89897123202991%; --time:3.6685731535289268s; --delay:-3.8697853233182427s;"></div>
    <div class="bubble"
         style="--size:4.971324691409686rem; --distance:8.186968733617851rem; --position:58.67890114571261%; --time:3.1612793313150025s; --delay:-2.311053074222861s;"></div>
    <div class="bubble"
         style="--size:5.939527659365764rem; --distance:6.507521621031426rem; --position:7.6637927279417255%; --time:3.1709469937574344s; --delay:-2.6989912557237843s;"></div>
    <div class="bubble"
         style="--size:3.55509456829037rem; --distance:7.841223864180421rem; --position:48.638572188602566%; --time:2.9277397391936995s; --delay:-3.9158947459973596s;"></div>
    <div class="bubble"
         style="--size:3.4479069101427475rem; --distance:8.823367173120022rem; --position:64.87019682142552%; --time:2.427906782296387s; --delay:-2.49399105775799s;"></div>
    <div class="bubble"
         style="--size:4.598706572662613rem; --distance:8.689437012114382rem; --position:83.17137905557733%; --time:2.2505512845218014s; --delay:-3.1047123375163794s;"></div>
    <div class="bubble"
         style="--size:4.079981879179187rem; --distance:9.286627698931774rem; --position:40.1693936336598%; --time:3.2190285095074986s; --delay:-2.808425086012814s;"></div>
    <div class="bubble"
         style="--size:4.243903377902601rem; --distance:8.394009802151388rem; --position:47.644788800034085%; --time:2.066754083355337s; --delay:-3.1853942626236993s;"></div>
    <div class="bubble"
         style="--size:3.0824054972614494rem; --distance:8.909923271746088rem; --position:33.14298296901021%; --time:2.7663983114832424s; --delay:-3.8528656586681302s;"></div>
    <div class="bubble"
         style="--size:2.318742564572978rem; --distance:7.415385933877048rem; --position:3.371626418173623%; --time:2.99592118578033s; --delay:-2.828298097492447s;"></div>
    <div class="bubble"
         style="--size:2.954050616250827rem; --distance:7.582581136868148rem; --position:14.177092341430011%; --time:2.742537758207623s; --delay:-3.676526223492918s;"></div>
    <div class="bubble"
         style="--size:4.696779796936363rem; --distance:7.6714473086804rem; --position:27.286810958283482%; --time:2.4821343686694486s; --delay:-2.310590342924666s;"></div>
    <div class="bubble"
         style="--size:4.370135094561155rem; --distance:7.594240111251339rem; --position:71.9328306508061%; --time:2.2369887338387295s; --delay:-2.478804960932942s;"></div>
    <div class="bubble"
         style="--size:4.883769325121705rem; --distance:6.91029822736731rem; --position:103.26924468062245%; --time:2.244187801128203s; --delay:-3.6103079716256623s;"></div>
    <div class="bubble"
         style="--size:2.3726304891050907rem; --distance:8.151647240762191rem; --position:45.226767155900305%; --time:2.3527278457439476s; --delay:-3.7489665803589327s;"></div>
    <div class="bubble"
         style="--size:2.6765708210048818rem; --distance:6.141685481211681rem; --position:33.25415289443421%; --time:3.0530891035370007s; --delay:-2.800172501828881s;"></div>
    <div class="bubble"
         style="--size:3.1042984240571583rem; --distance:9.933405808145096rem; --position:73.34199247822438%; --time:3.458779326957349s; --delay:-3.34141746847463s;"></div>
    <div class="bubble"
         style="--size:5.208966665591438rem; --distance:6.523241653229809rem; --position:34.65281674924534%; --time:3.923063884556263s; --delay:-3.715996371389474s;"></div>
    <div class="bubble"
         style="--size:3.224257178321686rem; --distance:7.965825513351805rem; --position:87.24095445326945%; --time:3.1594272247281183s; --delay:-3.149309592204493s;"></div>
    <div class="bubble"
         style="--size:2.5063772930075183rem; --distance:6.245745533720683rem; --position:80.9502183933854%; --time:2.13468380878082s; --delay:-2.4603848195085507s;"></div>
    <div class="bubble"
         style="--size:4.946052356366426rem; --distance:8.467541745962414rem; --position:82.81936190578487%; --time:3.701326258743365s; --delay:-2.0259297107895975s;"></div>
    <div class="bubble"
         style="--size:4.988288115395924rem; --distance:7.554568706894471rem; --position:0.9053945565015153%; --time:3.8452974585338278s; --delay:-3.478161659764522s;"></div>
    <div class="bubble"
         style="--size:2.881530910479098rem; --distance:9.174936395614782rem; --position:-2.0667880169735398%; --time:3.808792869004792s; --delay:-2.629645513186038s;"></div>
    <div class="bubble"
         style="--size:3.0256995982193544rem; --distance:7.682676066744788rem; --position:59.25687221731961%; --time:2.00462007399246s; --delay:-2.462412281123439s;"></div>
    <div class="bubble"
         style="--size:2.825449018644729rem; --distance:7.98814270058774rem; --position:46.83697128755965%; --time:3.246160172028146s; --delay:-3.9578635969469196s;"></div>
    <div class="bubble"
         style="--size:4.69638528215575rem; --distance:8.305937181449025rem; --position:14.521985997470605%; --time:2.268141892420429s; --delay:-3.0949059753667076s;"></div>
    <div class="bubble"
         style="--size:5.664641031986027rem; --distance:9.142077773310284rem; --position:60.769078750763924%; --time:3.079563820882949s; --delay:-2.114370850484608s;"></div>
    <div class="bubble"
         style="--size:2.143481995227285rem; --distance:9.475073266620749rem; --position:36.341459613609246%; --time:3.2518670842276522s; --delay:-3.31301460425288s;"></div>
    <div class="bubble"
         style="--size:4.253990934089128rem; --distance:7.484851552802329rem; --position:21.543024087474706%; --time:2.2639601855019684s; --delay:-3.1483715911797736s;"></div>
    <div class="bubble"
         style="--size:2.739466004827281rem; --distance:9.333974080052602rem; --position:-3.4752312650482%; --time:3.637163645927192s; --delay:-2.6447709803662147s;"></div>
    <div class="bubble"
         style="--size:3.13098897676585rem; --distance:8.444148314251976rem; --position:85.58117543464118%; --time:2.2154994804556165s; --delay:-3.251053431511577s;"></div>
    <div class="bubble"
         style="--size:3.3019098805223264rem; --distance:9.22357141458266rem; --position:49.34740277690913%; --time:2.615443943953469s; --delay:-2.6428304640201175s;"></div>
    <div class="bubble"
         style="--size:5.5696258414111695rem; --distance:6.970515027877212rem; --position:1.446896994031932%; --time:2.780611353893854s; --delay:-3.527167245345058s;"></div>
    <div class="bubble"
         style="--size:2.3571551857372954rem; --distance:6.098695672939222rem; --position:0.2997735030461657%; --time:2.974640003192696s; --delay:-2.9350383349080365s;"></div>
    <div class="bubble"
         style="--size:4.344987685749073rem; --distance:8.452965492892053rem; --position:72.64922759491348%; --time:3.242409876837895s; --delay:-3.169967991289542s;"></div>
    <div class="bubble"
         style="--size:2.8165282732375845rem; --distance:7.15262313237139rem; --position:86.06751977945501%; --time:2.320512938495323s; --delay:-3.134000640189208s;"></div>
    <div class="bubble"
         style="--size:4.129795641512427rem; --distance:6.901887477964646rem; --position:103.15650962720105%; --time:2.7511619647679404s; --delay:-3.9969876744388952s;"></div>
    <div class="bubble"
         style="--size:5.763657777372449rem; --distance:9.240767360040593rem; --position:36.118625552833436%; --time:3.983935457802799s; --delay:-3.5432904733278545s;"></div>
    <div class="bubble"
         style="--size:4.653026852176459rem; --distance:6.95360294221863rem; --position:-0.4768910389535863%; --time:3.541777598533169s; --delay:-2.8704244202973577s;"></div>
    <div class="bubble"
         style="--size:3.4034809840382607rem; --distance:7.194068660633692rem; --position:-3.060806107199905%; --time:2.690131203034362s; --delay:-3.3179530989434562s;"></div>
    <div class="bubble"
         style="--size:3.7809689065873773rem; --distance:7.651442380568289rem; --position:78.044175298973%; --time:3.9059754265557864s; --delay:-3.5613605877498107s;"></div>
    <div class="bubble"
         style="--size:4.909372117592863rem; --distance:7.92346526487194rem; --position:74.23079873579947%; --time:2.636002915062367s; --delay:-3.9898097736247196s;"></div>
    <div class="bubble"
         style="--size:2.0326660532589784rem; --distance:9.863441489462138rem; --position:14.80681112187344%; --time:3.1370866977023804s; --delay:-3.819801412041107s;"></div>
    <div class="bubble"
         style="--size:3.157021618330612rem; --distance:6.093659712214073rem; --position:48.2195952430969%; --time:2.6207330686222616s; --delay:-2.7444749883343973s;"></div>
    <div class="bubble"
         style="--size:5.2663859151263415rem; --distance:7.339629106185383rem; --position:49.04625024710177%; --time:3.1013793093237205s; --delay:-3.3323634524881625s;"></div>
    <div class="bubble"
         style="--size:2.4642859618603152rem; --distance:9.969412463184192rem; --position:96.30050702668296%; --time:3.7834071978486423s; --delay:-3.035498510160765s;"></div>
    <div class="bubble"
         style="--size:3.1040502309727733rem; --distance:7.707622377974843rem; --position:37.026937231633106%; --time:2.892038701717317s; --delay:-3.5411016555823402s;"></div>
    <div class="bubble"
         style="--size:4.017747503342398rem; --distance:8.79189680294332rem; --position:79.68895625501932%; --time:3.4962156959179325s; --delay:-2.045051588177828s;"></div>
    <div class="bubble"
         style="--size:5.0426571238569755rem; --distance:7.854818198448356rem; --position:62.77139042908617%; --time:2.0323297820821904s; --delay:-2.6596009265270886s;"></div>
    <div class="bubble"
         style="--size:4.0864571738495625rem; --distance:7.68091570295819rem; --position:66.74084798439543%; --time:3.6603937390257966s; --delay:-3.8298298346554787s;"></div>
    <div class="bubble"
         style="--size:2.1909457194305446rem; --distance:9.669522786212276rem; --position:96.53255517274576%; --time:3.6488151155587447s; --delay:-2.117274567738978s;"></div>
  </div>
  <div class="content">
    <div class="content__agb">
      <div class="content__badge" routerLink="/policy">Privacy policy</div>
      <div class="content__badge" routerLink="/terms">Terms of service</div>
    </div>
    <p class="content__address">
      All rights are revered by Greyt.IT UG © 2023
    </p>
    <section class="social-wrapper">
      <a class="icon-link" href="https://www.instagram.com/getme.global/">
        <img src="assets/icons/instagram.svg" alt="Instagram link"></a>
      <a class="icon-link" href="https://www.facebook.com/getmeadvice">
        <img src="assets/icons/facebook.svg" alt="Facebook link"></a>
      <a class="icon-link" href="https://www.linkedin.com/company/17961733">
        <img src="assets/icons/linkedin.svg" alt="LinkedIn link"></a>
      <a class="icon-link" href="https://www.youtube.com/channel/UCUlwWMtjW2E-iMs5VcKBaqw">
        <img src="assets/icons/youtube.svg" alt="Youtube link"></a>
    </section>
  </div>
</div>
<svg style="position:fixed; top:100vh">
  <defs>
    <filter id="blob">
      <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur"></feGaussianBlur>
      <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                     result="blob"></feColorMatrix>
      <feComposite in="SourceGraphic" in2="blob" operator="atop"></feComposite>
    </filter>
  </defs>
</svg>
