<div class="wrapper" #wrapperRef>
	<div class="audio-container">
		<div class="not-loaded" *ngIf="!loaded">
			<button class="load-message"
							[ngClass]="{ 'load-message--loading': loading }"
							[disabled]="paymentRequestAmount"
							(click)="load()"
							(click)="$event.stopPropagation()">
				<img src="assets/payments/play-audio-variant.svg" *ngIf="!loading">
				<app-small-spinner diameter="20" *ngIf="loading"></app-small-spinner>
			</button>
	
			<div class="progress"></div>
	
			<div class="duration" [ngClass]="{ 'duration--disabled': !canSelectLanguage }" (click)="openDropDownMenu()" [attr.data-lang]="language">{{ audioMessage.duration | hms }}</div>
		</div>
	
		<div *ngIf="loaded" class="plyr-container">
			<div plyr
					(click)="$event.stopPropagation()"
					preload="none"
					class="player"
					plyrType="audio"
					[plyrPlaysInline]="true"
					[plyrOptions]="playerOptions"
					[plyrSources]="plyrSources"></div>
		</div>
	
		<button mat-button [matMenuTriggerFor]="menu" class="trigger"></button>
		<mat-menu #menu="matMenu" class="langs">
			<button mat-menu-item
							role="menuitemradio"
							class="langs__item"
							[ngClass]="{ 'langs__item--selected': language === lang }"
							(click)="onSelect(lang)"
							*ngFor="let lang of languages">{{ lang }}</button>
		</mat-menu>
	</div>
</div>