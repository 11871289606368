import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { User } from 'src/app/shared/models/user.model';
import { AuthService } from 'src/app/auth/auth.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OnlineService } from 'src/app/services/online.service';
import { SharedService } from 'src/app/services/shared.service';
import { WebsocketService } from 'src/app/services/websocket.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { LogoErrorModalComponent } from '../logo-error-modal/logo-error-modal.component';
import { Observable } from 'rxjs';
import { SearchService } from '../../../../services/search.service';
import { ShareProfileModalComponent } from '../../share-profile-modal/share-profile-modal.component';
import { AdvisorsService } from '../../../../services/advisors.service';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGES } from '../../../enums';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent extends BaseComponent implements OnInit {
  public currentUser: User;
  readonly LANGUAGES = LANGUAGES;
  public returnUrl: string;
  public isMediumScreen$: Observable<boolean>;
  public showNewFeedback = true;
  currentUserPublicProfile$: Observable<User>;
  currentUserPublicProfile: User;
  selectedSkills$ = this.searchService.selectedSkills$.asObservable();
  languagesMenuSelect = this.languageItems;
  currentSelectedLang = this.translateService.currentLang;

  public get unreadCount(): number {
    return this.messagesService.unreadMessages$.value;
  }

  public get isInsights(): boolean {
    return /insights/.test(this.router.url);
  }

  public get isCreatives(): boolean {
    return /creatives/.test(this.router.url);
  }

  public get isAdvisors(): boolean {
    return /advisors/.test(this.router.url);
  }

  public get isPublicFeed(): boolean {
    return /publicfeed/.test(this.router.url);
  }

  constructor(
    private authService: AuthService,
    private messagesService: MessagesService,
    private onlineService: OnlineService,
    private webSocketService: WebsocketService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef,
    public router: Router,
    public sharedService: SharedService,
    private searchService: SearchService,
    private advisorsService: AdvisorsService,
    private readonly translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.selectedSavedLangBefore();
    this.subscribeToRouter();
    this.subscribeToUser();
    this.observeBreakpoint();
  }

  private get languageItems(): Array<any> {
    return this.translateService.getLangs().map((langCode: string)=> {
      return {
        title: langCode === LANGUAGES.ENGLISH ? 'English' : 'Deutsch',
        value: langCode
      };
    });
  }

  selectLang(langCode: string) {
    this.translateService.use(langCode);
    this.currentSelectedLang = this.translateService.currentLang;
    localStorage.setItem('langCode', langCode);
  }

  private selectedSavedLangBefore() {
    const savedLang: LANGUAGES = localStorage.getItem('langCode') as LANGUAGES;
    if (savedLang) {
      this.selectLang(savedLang);
    }
  }

  private observeBreakpoint() {
    this.isMediumScreen$ = this.breakpointObserver.observe('(max-width: 1180px)').pipe(map(res => res.matches));
  }

  subscribeToRouter() {
    this.router.events.pipe(
      takeUntil(this.destroyed),
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      if (this.isInsights) {
        this.showNewFeedback = false;
      }

      this.returnUrl = location.pathname + location.search;
    });
  }

  subscribeToUser() {
    this.authService.userSubject$.pipe(
      tap(res => {
        this.currentUser = res;
        if (res) {
          this.currentUserPublicProfile$ = this.advisorsService.fetchAdvisorProfile(res.id)
            .pipe(tap((user: User) => this.currentUserPublicProfile = user));
        }
        this.cdr.detectChanges();
      }),
      takeUntil(this.destroyed)
    ).subscribe();
  }

  onLogout() {
    this.webSocketService.closeConnection();
    this.onlineService.subscribeToOnline().subscribe();
    this.sharedService.feedbackRequestsCount = 0;
    this.messagesService.unreadMessages$.next(0);
    this.authService.logout();
  }

  changeOnlineStatus(): void {
    if (!this.currentUser) {
      void this.router.navigateByUrl('/home');
      return;
    }

    if (!this.currentUserPublicProfile.user_skills.length) {
      this.dialog.open(LogoErrorModalComponent, {
        maxWidth: `870px`,
        autoFocus: false,
        panelClass: 'modal',
        data: {step: 5}
      });
      return;
    }

    this.currentUser.online = !this.currentUser.online;
    this.onlineService.updateOnlineStatus(this.currentUser.online, this.currentUser.id).pipe(
      takeUntil(this.destroyed),
      tap(res => this.authService.userSubject$.next(res))
    ).subscribe();
  }

  shareYourProfile(user: User): void {
    this.dialog.open(ShareProfileModalComponent, {
      maxWidth: 877,
      width: '100%',
      data: user
    });
  }
}
