import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { API_URL } from 'src/config/config';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.userIsSignedIn() && request.url.includes(API_URL)) {
      request = request.clone({
        setHeaders: {
          'access-token': localStorage.getItem('accessToken'),
          'token-type': localStorage.getItem('tokenType'),
          client: localStorage.getItem('client'),
          uid: localStorage.getItem('uid'),
          expiry: localStorage.getItem('expiry')
        }
      });
    }
    return next.handle(request);
  }
}
