import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { MetaGuard } from '@ngx-meta/core';
import { GuestGuard } from './auth/guest.guard';

const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {
    path: 'publicfeed',
    loadChildren: () => import('./pages/newsfeed/newsfeed.module').then(m => m.NewsfeedModule),
    canActivate: [MetaGuard],
    data: {
      meta: {
        keywords: 'Find Creative Project, Find Art Project',
        title: 'Find your Creative Project!',
        description: 'Discover exciting art projects from our community and collaborate with their creators to boost the potential of ideas together. Inspire and be inspired.',
      }
    }
  },
  {
    path: 'rateflow',
    loadChildren: () => import('./pages/rateflow/rateflow.module').then(m => m.RateflowModule),
    canActivate: [MetaGuard],
    data: {
      meta: {
        keywords: 'Give Feedback',
        title: 'Give Feedback',
        description: 'Give feedback',
      }
    }
  },
  {
    path: 'transactions',
    loadChildren: () => import('./pages/transactions/transactions.module').then(m => m.TransactionsModule)
  },
  {
    path: 'insights',
    loadChildren: () => import('./pages/my-projects/my-projects.module').then(m => m.MyProjectsModule),
    canActivate: [MetaGuard, AuthGuard],
    data: {
      meta: {
        keywords: 'My Projects',
        title: 'My Projects',
        description: 'My projects',
      }
    }
  },
  {
    path: 'insights',
    loadChildren: () => import('./pages/insights/insights.module').then(m => m.InsightsModule),
    canActivate: [MetaGuard, AuthGuard],
    data: {
      meta: {
        keywords: 'My Projects',
        title: 'My Projects',
        description: 'My projects',
      }
    }
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'upload',
    loadChildren: () => import('./pages/upload/upload.module').then(m => m.UploadModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/usersettings/usersettings.module').then(m => m.UsersettingsModule),
    canActivate: [MetaGuard, AuthGuard],
    data: {
      meta: {
        keywords: 'Settings',
        title: 'Settings',
        description: 'settings',
      }
    }
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [MetaGuard],
    data: {
      meta: {
        keywords: 'Profile',
        title: 'Profile',
        description: 'profile',
      }
    }
  },
  {
    path: 'why',
    loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule),
    canActivate: [MetaGuard],
    data: {
      meta: {
        keywords: 'Creative Networking, Art Networking, Art Community',
        title: 'The Creative Networking for Creatives',
        description: 'Getme.Global is the artistic community where creatives are inspired by feedback for projects in fields like music, photography, film, commerce and more!',
      }
    }
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact-us/contact-us.module').then(m => m.ContactUsModule),
    canActivate: [MetaGuard],
    data: {
      meta: {
        keywords: 'Contact Us',
        title: 'Contact Us',
        description: 'Contact us',
      }
    }
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsModule),
    canActivate: [MetaGuard],
    data: {
      meta: {
        keywords: 'Terms of Service',
        title: 'Terms of Service',
        description: 'Find all the info you need to connect with Getme.Global and our artistic community safely and securely: terms and conditions, pricing, intellectual property.',
      }
    }
  },
  {
    path: 'policy',
    loadChildren: () => import('./pages/policy/policy.module').then(m => m.PolicyModule),
    canActivate: [MetaGuard],
    data: {
      meta: {
        keywords: 'Privacy Policy',
        title: 'Privacy Policy',
        description: 'Getme.Global has a strict privacy policy that guarantees the protection and confidentiality of your personal data. Check it out!',
      }
    }
  },
  {
    path: 'creatives',
    canActivate: [MetaGuard],
    loadChildren: () => import('./pages/creatives/creatives.module').then(m => m.CreativesModule),
    data: {
      meta: {
        keywords: 'Find Creatives, Find Artists',
        title: 'Find your Creatives!',
        description: 'Connect with artists open-minded to inspiring reviews. Choose your favorites and give them paid feedback that can make a difference in their works.',
      }
    }
  },
  {
    path: 'advisors',
    loadChildren: () => import('./pages/advisors/advisors.module').then(m => m.AdvisorsModule),
    canActivate: [MetaGuard],
    data: {
      advisorFilter: true,
      meta: {
        keywords: 'Find Art Advice, Find Art Advisors, Find Art Experts',
        title: 'Find your Art Advice!',
        description: 'Learn from human advice around ideas, skills or tools you are blocked. Once you contact the expert advisor, solve your problem by chat or video stream.',
      }
    }
  },
  {
    path: 'stripe/token',
    loadChildren: () => import('./auth/stripe/stripe.module').then(m => m.StripeModule)
  },
  {
    path: 'registrationconfirm',
    loadChildren: () => import('./auth/confirm/confirm.module').then(m => m.ConfirmModule)
  },
  {
    path: 'confirmreset',
    loadChildren: () => import('./auth/confirmreset/confirmreset.module').then(m => m.ConfirmresetModule)
  },
  {
    path: 'auth',
    canLoad: [GuestGuard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    data: {
      signup: {
        meta: {
          keywords: 'Sign up',
          title: 'Sign up',
          description: 'Want to start connecting with creatives and receive constructive feedback for your artwork with Getme.Global? Sign up here!',
        }
      },
      login: {
        meta: {
          keywords: 'Sign in',
          title: 'Log in',
          description: 'Are you ready for your next contact with art industry experts and companies? What are you waiting for to log in?',
        }
      },
      resetpassword: {
        meta: {
          keywords: 'Reset Password',
          title: 'Reset Password',
          description: 'Reset password',
        }
      },
    }
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/why/why.module').then(m => m.WhyModule),
    data: {
      home: {
        meta: {
          keywords: 'Feedback for Artwork, Feedback for Creatives',
          title: 'It’s Not Enough To Just Be Real, Get Real.',
          description: 'Getme.Global allows users to give and ask for constructive reviews on artwork, anonymously and for free. Find insighful feedback for creatives by creatives.',
        }
      },
      howtouse: {
        meta: {
          keywords: 'Art Consultation, Art Platform',
          title: 'The Art Consultation Platform for Creatives, Advisors and Companies',
          description: 'Upload your artwork to the platform, ask specific questions and give feedback to others. Getting quality reviews in a safe environment is now a reality.',
        }
      },
      fullstory: {
        meta: {
          keywords: 'Creative Marketplace, Art Industries',
          title: 'The Creative Marketplace for Art Industries',
          description: 'The unique online marketplace where feedback and inspiration bring creatives together to drive ideas in areas like photography, film, music or commerce.',
        }
      }
    }
  },
  {
    path: 'why-pr',
    loadChildren: () => import('./pages/why/why.module').then(m => m.WhyModule),
    data: {
      whyjoin: {
        meta: {
          keywords: 'Feedback for Artwork, Feedback for Creatives',
          title: 'The Insightful Feedback for Artwork',
          description: 'Getme.Global allows users to give and ask for constructive reviews on artwork, anonymously and for free. Find insighful feedback for creatives by creatives.',
        }
      },
      howtouse: {
        meta: {
          keywords: 'Art Consultation, Art Platform',
          title: 'The Art Consultation Platform for Creatives, Advisors and Companies',
          description: 'Upload your artwork to the platform, ask specific questions and give feedback to others. Getting quality reviews in a safe environment is now a reality.',
        }
      },
      fullstory: {
        meta: {
          keywords: 'Creative Marketplace, Art Industries',
          title: 'The Creative Marketplace for Art Industries',
          description: 'The unique online marketplace where feedback and inspiration bring creatives together to drive ideas in areas like photography, film, music or commerce.',
        }
      }
    }
  },
  {
    path: 'conversations',
    canActivate: [MetaGuard, AuthGuard],
    data: {
      meta: {
        keywords: 'My Activity',
        title: 'My Activity',
        description: 'My activity',
      }
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/conversations/conversations.module').then(m => m.ConversationsModule),
      },
      {
        path: 'newconversation',
        loadChildren: () => import('./pages/conversation/conversation.module').then(m => m.ConversationModule)
      },
      {
        path: ':id',
        loadChildren: () => import('./pages/conversation/conversation.module').then(m => m.ConversationModule)
      },
    ],
  },
  {
    path: 'create-ice-breaker',
    loadChildren: () => import('./pages/create-ice-breaker/create-ice-breaker.module').then(m => m.CreateIceBreakerModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64]
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
