import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { ServerErrorInterceptor } from './shared/interceptors/server-error.interceptor';

import { ServiceWorkerModule } from '@angular/service-worker';
import { NgPipesModule } from 'ngx-pipes';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { RouterModule } from '@angular/router';

import { ActionCableService } from 'angular2-actioncable';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { SharedModule } from './shared/shared.module';
import { CookieNotificationComponent } from './shared/components/cookie-notification/cookie-notification.component';
import { ShareModule } from 'ngx-sharebuttons';
import { WebPushPlayerIdInterceptor } from './shared/interceptors/web-push-player-id.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { MessageNotificationModule } from './shared/components/message-notification/message-notification.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslationConfigInitialize } from './shared/functions/translation.config';
import { HeaderModule } from './shared/components/header/header.module';
import { SortablejsModule } from 'ngx-sortablejs-v16';
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    CookieNotificationComponent
  ],
    imports: [
        SortablejsModule.forRoot({animation: 150}),
        HttpClientJsonpModule,
        NgPipesModule,
        RouterModule,
        SharedModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        ToastrModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        LayoutModule,
        ShareModule,
        MessageNotificationModule,
        TranslateModule.forRoot(),
        HeaderModule
    ],
  providers: [
    ActionCableService,
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: WebPushPlayerIdInterceptor, multi: true },
    { provide: APP_INITIALIZER,
      useFactory: TranslationConfigInitialize,
      deps: [ TranslateService, Injector ],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {




}
