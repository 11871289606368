<div class="text-input__wrapper" #wrapperRef [ngClass]="{'pb-0': isRecording || audioMessage?.url}">
  <section class="text-input-area">
    <div class="text-input-area__field" (click)="messageInputRef.focus()">
      <p #messageInputRef
         class="text-input__input"
         [contentEditable]="editable"
         [attr.placeholder]="inputPlaceholder"
         (keyup)="onMessageKeyUp()"
         (paste)="detectPaste($event)"></p>
    </div>
    <div class="text-input__btns" #inputBnts>
      <button (click)="insertMessage()"
              [disabled]="!messagesService.copiedMessage"
              class="text-input__btn text-input__insert-btn"
              *ngIf="showInsertBtn">
        <img src="assets/plus.svg" alt="">
      </button>

      <button (click)="record()"
              *ngIf="allowAudioMessage"
              class="text-input__btn text-input__record-btn"
              [disabled]="recordBtnDisabled">
        <img src="/assets/icons/microphone.svg">
      </button>

      <button (click)="send()"
              *ngIf="showSendBtn && inputIsEnabled"
              class="text-input__btn text-input__send-btn"
              [ngClass]="{'scale-send-btn': scaleSendBtn}"
              [disabled]="!inputIsEnabled">
        <img [src]="sendBtnSrc">
      </button>
    </div>
  </section>

  <div class="record-audio" *ngIf="isRecording" [ngStyle]="{'width.px': wrapperRef.clientWidth}">
    <app-audio-record
      [isFlexFeedback]="true"
      [user]="user"
      [stopRecording$]="stopRecordingEvt.asObservable()"
      (uploadingFinished)="audioMessageUploaded($event)"
      (close)="deleteAudioMessage()"
    ></app-audio-record>
  </div>

  <div class="audio-message" *ngIf="audioMessage?.url">
    <app-audio-message
      [audioMessage]="audioMessage"
      [disablePreload]="true"
      [canSelectLanguage]="true"
      [userLanguages]="user.lang"
    ></app-audio-message>
    <button class="delete-audio delete-btn" (click)="deleteAudioMessage(true)">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="file" *ngIf="file">
    <div class="file__preview">
      <img [src]="fileBase64" alt="">
    </div>
    <button (click)="deleteFile()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
