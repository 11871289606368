<div class="modal__container">
  <button mat-icon-button mat-dialog-close class="modal__btn--close">
    <img src="assets/close-modal-icon.svg" alt="close-modal-icon">
  </button>

  <div class="modal__body">
    <h1 class="modal__title">{{ data.message }}</h1>
  </div>

  <div class="modal__actions">
    <button class="modal__btn" mat-dialog-close>Cancel</button>
    <button class="modal__btn accented-btn" [mat-dialog-close]="true">Confirm</button>
  </div>
</div>