<div class="container">
  <app-header class="header" *ngIf="!isAuthPage"></app-header>

  <main class="content" [ngClass]="{'mt-0': isAuthPage}" [@fader]="outlet.isActivated ? outlet.activatedRoute : ''">
    <router-outlet #outlet="outlet"></router-outlet>
  </main>

  <app-cookie-notification
    [@slideInOut]
    *ngIf="showCookieNotification"
    (hideNotification)="showCookieNotification = false"
    (agree)="hideNotification()"
  ></app-cookie-notification>
</div>
