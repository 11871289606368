import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { MenuItem } from '../../models/menu-item';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})

export class MenuComponent extends BaseComponent implements OnInit {
  @Input() preselectedItem: MenuItem;
  @Input() menuItems: MenuItem[];
  @Input() split: boolean;
  @Input() numberOfVisibleItems = 2;
  @Input() private resetItems$: Observable<void>;

  @Output() didSelectMenuItem = new EventEmitter<number>();

  dropdownItems: MenuItem[];
  visibleItems: MenuItem[] = [];

  activeMenuIndex: number;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.activeMenuIndex = this.menuItems.findIndex(item => item.param === this.preselectedItem.param);
    this.visibleItems = this.menuItems.filter(item => item.keepPosition);

    let numberOfKeepPosition = this.visibleItems.length;

    if (!this.visibleItems.find(item => item.param === this.preselectedItem.param)) {
      this.visibleItems.push(this.preselectedItem);
      numberOfKeepPosition++;
    }

    this.visibleItems.push(
      ...this.menuItems.filter(i => i.param !== this.preselectedItem.param && !i.keepPosition)
                        .splice(0, this.numberOfVisibleItems - numberOfKeepPosition)
    );

    this.dropdownItems = this.menuItems.filter(i => !this.visibleItems.find(vItem => vItem.param === i.param));

    this.resetItems$?.pipe(
      takeUntil(this.destroyed),
      tap(() => {
        this.visibleItems = this.menuItems.slice(0, 2);
        this.dropdownItems = this.menuItems.slice(2);
      })
    ).subscribe();
  }

  didSelectItem(item: MenuItem) {
    this.activeMenuIndex = this.menuItems.findIndex(i => i.param === item.param);

    const index = this.dropdownItems.findIndex(i => i.param === item.param);
    if (index !== -1) {
      this.visibleItems.pop();
      this.visibleItems.push(this.menuItems[this.activeMenuIndex]);
      this.dropdownItems = this.menuItems.filter(i => !this.visibleItems.map(vItem => vItem.param).includes(i.param));
    }

    this.didSelectMenuItem.emit(this.activeMenuIndex);
  }
}
