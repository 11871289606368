import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActionCableService, Channel } from 'angular2-actioncable';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, filter } from 'rxjs/operators';
import { API_URL } from 'src/config/config';
import { environment } from 'src/environments/environment';
import { User } from '../shared/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class OnlineService {
  onlineChannel: Channel;
  userChangedOnlineStatus$ = new BehaviorSubject<{ id: number, online: boolean }>(null);

  private get channelParams(): { accessToken: string; client: string, uid: string } {
    const accessToken = localStorage.getItem('accessToken');
    const client = localStorage.getItem('client');
    const uid = localStorage.getItem('uid');

    return { accessToken, client, uid };
  }

  constructor(
    private cableService: ActionCableService,
    private http: HttpClient,
  ) { }

  subscribeToOnline() {
    const { accessToken, client, uid } = this.channelParams;

    this.onlineChannel = this.cableService
      .cable(`${environment.wsBase}?access-token=${accessToken}&client=${client}&uid=${uid}`)
      .channel('AppearanceChannel', { room: 'appearance_channel' });

    return this.onlineChannel.received().pipe(
      tap(res => this.userChangedOnlineStatus$.next(res)),
      filter(message => !message.updated),
    );
  }

  updateOnlineStatus(value: boolean, userId: number): Observable<User> {
    const url = API_URL + `/users/${userId}`;

    return this.http.put(url, { online: value });
  }
}
