import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MESSAGES_URL, MESSAGE_URL, PAYMENT_SESSION_URL, UNREAD_MESSAGES_URL } from 'src/config/config';
import { Message } from '../shared/models/message.model';
import { PaymentSession } from '../shared/models/payment-session';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  unreadMessages$ = new BehaviorSubject<number>(0);
  unreadConversationsMessages$ = new BehaviorSubject<{ my_advice: number; my_works: number; }>({ my_advice: 0, my_works: 0 });
  public copiedMessage: Message;

  public get unreadMessages(): number {
    return this.unreadMessages$.value;
  }

  constructor(
    private http: HttpClient,
  ) { }

  getMessage(messageId: number) {
    return this.http.get<Message>(MESSAGE_URL(messageId));
  }

  updateMessage(messageId: number, body: Partial<Message>): Observable<Message> {
    return this.http.put<Message>(MESSAGE_URL(messageId), { ...body });
  }

  public deleteMessage(id: number) {
    return this.http.delete(MESSAGE_URL(id));
  }

  markMessageRead(messageId: number) {
    return this.http.put<Message>(MESSAGE_URL(messageId), { read: true }).pipe(
      tap(() => this.unreadMessages$.next(this.unreadMessages ? this.unreadMessages - 1 : 0))
    );
  }

  getSession(id: number): Observable<PaymentSession> {
    return this.http.get(PAYMENT_SESSION_URL(id));
  }

  markSessionRead(id: number): Observable<PaymentSession> {
    return this.http.put(PAYMENT_SESSION_URL(id), { read: true }).pipe(
      tap(() => this.unreadMessages$.next(this.unreadMessages ? this.unreadMessages - 1 : 0))
    );
  }

  acceptPaymentRequestForMessage(messageId: number) {
    return this.http.put<Message>(MESSAGE_URL(messageId), { paymentrequestapproved: true });
  }

  denyPaymentForMessageID(messageId: number) {
    return this.http.put<Message>(MESSAGE_URL(messageId), { paymentrequestdenied: true });
  }

  createMessage(message: Message) {
    return this.http.post<Message>(MESSAGES_URL, { ...message, message_screenshots_attributes: message.message_screenshots });
  }

  fetchMessagesCount(): void {
    this.http.get<{ my_advice: number; my_works: number; }>(UNREAD_MESSAGES_URL).pipe(
      tap(res => {
        this.unreadMessages$.next(res.my_advice + res.my_works);
        this.unreadConversationsMessages$.next(res);
      })
    ).subscribe();
  }
}
