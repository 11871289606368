import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  API_URL, CONVERSATIONS_URL, CONVERSATION_URL, LESSONS_URL,
  LESSON_PAYMENT_STATUS_UPDATE_URL, LESSON_URL, PAYMENT_SESSIONS_STATUS_UPDATE_URL,
  PAYMENT_SESSIONS_URL, PAYMENT_SESSION_URL
} from 'src/config/config';
import { map } from 'rxjs/operators';
import { Conversation } from '../shared/models/conversation.model';
import { Lesson } from '../shared/models/lesson.model';
import { Message } from '../shared/models/message.model';
import { PaymentSession } from 'src/app/shared/models/payment-session';
import { PaymentRequestBySeconds } from 'src/app/shared/models/payment-request';
import { UserSkill } from '../shared/models/UserSkill.model';
import { IS_CREATED_ICE_BREAKER_BEFORE } from '../shared/constants';
import { User } from '../shared/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class ConversationsService {
  public activeConversation = new BehaviorSubject<number>(null);

  constructor(
    private http: HttpClient
  ) {
  }

  public fetchConversation(id: number) {
    return this.http.get<Conversation>(CONVERSATION_URL(id));
  }

  public fetchLessons() {
    return this.http.get<Lesson[]>(LESSONS_URL);
  }

  private createLesson(lesson: Lesson) {
    return this.http.post<Lesson>(LESSONS_URL, {lesson});
  }

  public updateLesson(lesson: any) {
    return this.http.put<Lesson>(LESSON_URL(lesson.id), {lesson});
  }

  public deleteLesson(lesson: Lesson) {
    return this.http.delete<Lesson>(LESSON_URL(lesson.id));
  }

  public markConversationAsResolved(conversationID: number) {
    return this.http.put<Conversation>(CONVERSATION_URL(conversationID), {isclarification: false});
  }

  public createTutotingRequestLesson(conversationId: number, studentId: number, advisorId: number, meetingtime: string) {
    const lesson: Lesson = {
      meetingtime,
      student_id: studentId,
      teacher_id: advisorId,
      conversation_id: conversationId,
    };

    return this.createLesson(lesson);
  }

  public denyPaymentRequestForLesson(lessonID: number) {
    return this.http.put<Message>(LESSON_URL(lessonID), {paymentrequestdenied: true});
  }

  public createTutotingRequestConversation(
    sender_id: number, recipient_id: number, project_id: number, user_skill: UserSkill, advisorrate: number
  ) {
    return this.http.post<Conversation>(CONVERSATIONS_URL, {
      sender_id, recipient_id, project_id, user_skill, advisorrate
    });
  }

  public createClarificationConversation(sender_id: number, recipient_id: number, project_id: number) {
    return this.http.post<Conversation>(CONVERSATIONS_URL, {
      sender_id, recipient_id, project_id, isclarification: true
    });
  }

  public createConversation(sender_id: number, recipient_id: number, project_id: number, advisorrate: number, projectfile_id?: number) {
    return this.http.post<Conversation>(CONVERSATIONS_URL, {
      sender_id, recipient_id, project_id, projectfile_id, advisorrate
    });
  }

  public createRoom(student_id: number, teacher_id: number, conversation_id: number) {
    const url = API_URL + 'create_room/';

    return this.http.post(url, {lesson: {student_id, teacher_id, conversation_id}});
  }

  public closeRoom(lessonID: number) {
    const url = API_URL + 'close_room/';
    return this.http.post(url, {lessonID});
  }

  public markLessonAsConfirmed(lessonID: number, confirmed: boolean) {
    const lesson = {confirmed};
    return this.http.put<Lesson>(LESSON_URL(lessonID), {lesson});
  }

  public markLessonAsFinished(lessonID: number) {
    const lesson = {finished: true};
    return this.http.put<Lesson>(LESSON_URL(lessonID), {lesson});
  }

  public updatePrice(conversationId: number, newPrice: number) {
    return this.http.put(CONVERSATION_URL(conversationId), {advisorrate: newPrice});
  }

  public disconnectUserFromAdvisor(disconnectType: string, disconnectUserId: number) {
    const url = API_URL + 'disconnected_users';
    return this.http.post(url, {disconnected_user: {disconnect_type: disconnectType, disconnect_user_id: disconnectUserId}});
  }

  public createPaymentSession(): Observable<number> {
    return this.http.post<{ id: number }>(PAYMENT_SESSIONS_URL, {}).pipe(
      map(res => res.id),
    );
  }

  public updatePaymentSession(id: number, value: Partial<PaymentSession> | Partial<PaymentRequestBySeconds>): Observable<void> {
    return this.http.put<void>(PAYMENT_SESSION_URL(id), {...value});
  }

  public updatePaymentStatus(payment_id: string, type: 'lesson' | 'paymentSession'): Observable<void> {
    const url = type === 'paymentSession' ? PAYMENT_SESSIONS_STATUS_UPDATE_URL : LESSON_PAYMENT_STATUS_UPDATE_URL;

    return this.http.put<void>(url, {payment_id});
  }

  ifEverCreatedIceBreakerEarlier(currentUser: User): boolean {
    const isCreateBeforeIceBreaker = localStorage.getItem(IS_CREATED_ICE_BREAKER_BEFORE);
    return !!(currentUser.icebreakers.length || isCreateBeforeIceBreaker.length);
  }
}
