<div class="header">
  <div class="logo" (click)="changeOnlineStatus()">
    <img class="logo__image"
        [src]="currentUser?.online ? '/assets/logo/logo-online.svg' : '/assets/logo/logo.svg'"
        alt="logo">
  </div>

  <div class="header__container">
    <ng-container *ngIf="currentUserPublicProfile$ | async as currentUser">
      <ng-container *ngIf="currentUser.online">
        <button class="share-button" (click)="shareYourProfile(currentUser)">
          <ng-container *ngIf="!(isMediumScreen$ | async); else shareText">
            <span>Share your profile</span>
            <img src="assets/icons/share.svg" alt="Share Icon">
          </ng-container>
          <ng-template #shareText>
            <span>Share</span>
            <img src="assets/icons/share.svg" alt="Share Icon">
          </ng-template>
        </button>
      </ng-container>
    </ng-container>
    <div class="search-bar">
      <ng-container *ngIf="!(isMediumScreen$ | async)">
        <app-search-bar *ngIf="(selectedSkills$ | async).length"></app-search-bar>
      </ng-container>
    </div>

    <div class="header__items">
      <a class="header__item" [ngClass]="{'header__item--red': sharedService.feedbackRequestsCount}" routerLinkActive="header__item--active" routerLink="creatives">
        <img class="header__item-icon" src="assets/header/creatives.svg" alt="">
        <span class="header__item-text">Find <br> projects</span>
      </a>
      <button class="language-btn" [matMenuTriggerFor]="langSelect">
        {{
        currentSelectedLang == LANGUAGES.ENGLISH ? 'English' : 'Deutsch'
        }}
      </button>
      <mat-menu #langSelect="matMenu">
        <button mat-menu-item *ngFor="let lang of languagesMenuSelect"
        (click)="selectLang(lang.value)">
          {{lang.title}}
        </button>
      </mat-menu>

      <a class="header__item" routerLink="create-ice-breaker" routerLinkActive="header__item--active">
        <img class="header__item-icon" src="assets/ice-breaker/ice-breaker-logo.svg" alt="">
        <span class="header__item-text">Create <br> Ice Breaker</span>
      </a>

      <a class="header__item" routerLink="advisors" routerLinkActive="header__item--active">
        <img class="header__item-icon" src="assets/header/advisors.svg" alt="">
        <span class="header__item-text">Quick <br> questions</span>
      </a>

      <a class="header__item" routerLink="publicfeed" routerLinkActive="header__item--active">
        <img class="header__item-icon" src="assets/header/public-feed.svg" alt="">
        <span class="header__item-text">Public <br> feed</span>
      </a>

      <ng-container *ngIf="!currentUser">
        <a class="header__item" routerLink="why" routerLinkActive="header__item--active">
          <img class="header__item-icon" src="assets/header/why.svg" alt="">
          <span class="header__item-text">Why <br> getme</span>
        </a>

         <a class="header__item header__item--small-hidden header__item--blog" href="https://blog.getme.global">
          <img class="header__item-icon" src="assets/header/blog.svg" alt="">
          <span class="header__item-text">Our <br> blog</span>
        </a>

         <a class="header__item header__item--small-hidden" routerLinkActive="header__item--active" routerLink="about">
          <img class="header__item-icon" src="assets/header/about.svg" alt="">
          <span class="header__item-text">About <br> us</span>
        </a>

        <a class="header__item signup" routerLink="/auth" [queryParams]="{ mode: 'signup', returnUrl: returnUrl }">
          Sign up
          <img src="assets/header/signup.svg" alt="">
        </a>

        <a class="header__item header__item--small-hidden" routerLink="/auth" [queryParams]="{ mode: 'login', returnUrl: returnUrl }">
          <img class="header__item-icon" src="assets/header/login.svg" alt="">
          <span class="header__item-text">Log <br> in</span>
        </a>
      </ng-container>

      <ng-container *ngIf="currentUser">
     <a class="header__item" routerLink="rateflow" routerLinkActive="header__item--active">
          <img class="header__item-icon" src="assets/header/give-feedback.svg" alt="">
          <span class="header__item-text">Give <br> feedback</span>
        </a>

       <a class="header__item" routerLink="insights"
          [ngClass]="{'header__item--green': !isInsights && currentUser?.open_cards && showNewFeedback}"
          routerLinkActive="header__item--active" (click)="showNewFeedback = false">
          <img class="header__item-icon" src="assets/header/my-projects-filled.svg" alt="" *ngIf="isInsights || currentUser?.open_cards; else elseIcon">
          <ng-template #elseIcon>
            <img class="header__item-icon" src="assets/header/my-projects.svg" alt="">
          </ng-template>

          <span class="header__item-text">
            <ng-container *ngIf="!isInsights && currentUser?.open_cards && showNewFeedback; else myProjectsTpl">New <br> feedback</ng-container>
            <ng-template #myProjectsTpl>My <br> projects</ng-template>
          </span>
        </a>

        <a class="header__item" [routerLink]="'/profile/' + currentUser?.sharetoken" routerLinkActive="header__item--active">
          <img class="header__item-icon" src="assets/icons/user.svg" alt="">
          <span class="header__item-text">My public <br> profile</span>
        </a>

        <a class="header__item" routerLink="conversations" routerLinkActive="header__item--active">
          <div class="header__item-icon header__item-icon--inside">
            <img src="assets/header/conversations-filled.svg" alt="" *ngIf="unreadCount || router.url.includes('conversations'); else emptyConversationTpl">
            <ng-template #emptyConversationTpl>
              <img src="assets/header/conversations.svg" alt="">
            </ng-template>

            <i class="unread-count" [ngClass]="{'unread-count--small': unreadCount.toString().length > 2}" *ngIf="unreadCount">
              {{ unreadCount > 999 ? 999 : unreadCount }}
            </i>
          </div>

          <span class="header__item-text">Inbox</span>
        </a>
      </ng-container>
    </div>

    <div class="project-actions" [ngClass]="{'project-actions--not-logged': !currentUser}">
      <button mat-icon-button [matMenuTriggerFor]="menu" disableRipple>
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <ng-container *ngIf="currentUser">
       <a mat-menu-item routerLink="/upload" class="menu-btn" routerLinkActive="active">
            <img src="assets/header/upload.svg" alt="">
            <span>Upload</span>
          </a>
          <a mat-menu-item routerLink="/settings" routerLinkActive="active">
            <div class="menu-btn">
              <img src="assets/header/settings.svg" alt="">
              <span>Settings</span>
            </div>
          </a>
          <button mat-menu-item (click)="onLogout()" routerLinkActive="active">
            <div class="menu-btn">
              <img src="assets/header/logout.svg" alt="">
              <span>Logout</span>
            </div>
          </button>
        </ng-container>

        <a mat-menu-item routerLink="/auth" [queryParams]="{mode: 'login'}" *ngIf="!currentUser">
          <div class="menu-btn"><img src="assets/header/login.svg" alt="">
            <span>Log in</span></div>
        </a>

        <ng-container *ngIf="currentUser">
          <button mat-menu-item disabled>Signed in as {{ currentUser?.email }}</button>
          <mat-divider></mat-divider>

          <a mat-menu-item routerLink="/why" routerLinkActive="active">
            <div class="menu-btn"><img src="assets/header/why.svg" alt="">
              <span>Why getme</span></div>
          </a>
        </ng-container>

        <a mat-menu-item routerLink="/contact" routerLinkActive="active" *ngIf="currentUser">
          <div class="menu-btn"><img src="assets/header/contact.svg" alt="">
            <span>Contact us</span></div>
        </a>

<!--        <a mat-menu-item href="https://blog.Getme.Global" class="menu-btn">-->
<!--          <img src="assets/header/blog.svg" alt="">-->
<!--          <span>Blog</span>-->
<!--        </a>-->

        <a mat-menu-item routerLink="/home">
          <div class="menu-btn"><img src="assets/why/icons/home.svg" alt="home icon">
            <span>Home</span></div>
        </a>

        <a *ngIf="currentUser?.admin" mat-menu-item routerLink="/admin" routerLinkActive="active">
          <span>Open Admin</span>
        </a>
      </mat-menu>
    </div>
  </div>
</div>
