import { BonusItem } from '../interfaces/bonus-item';

export const Bonus: BonusItem[] = [
  {
    percent: '',
    info: 'Lower your commission forever by reaching the following milestones.'
  },
  {
    percent: '-1%',
    info: 'Complete our tutorial leaving 8 different reviews'
  },
  {
    percent: '-1%',
    info: 'Invite a new user, that makes a purchase'
  },
  {
    percent: '-2%',
    info: 'Invite a new user, that becomes an advisor'
  },
  {
    percent: '-3%',
    info: 'Receive inspiring rating badge from 10 different Creatives'
  },
  {
    percent: '-3%',
    info: 'Payout more then 200€ to your bank account'
  },
  {
    percent: '-5%',
    info: 'Payout more then 1000€ to your bank account'
  }
];
