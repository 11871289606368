export const environment = {
  production: true,
  analyticsEnabled: false,
  stripe: {
    pk: 'pk_live_LzQ4CNuT61LgvGJFqK0GUjdJ00Kdif31wf'
  },
  baseUrl: 'https://getme.global/',
  wsBase: 'wss://getme.global/cable',
  jitsiUrl: 'jitsi.getme.global',
  paypal: {
    clientID: 'AUE1k_EROS8ON5NWIfDF1Iys-nVG_h-oaiB9UA1FkLMKmD2827gbxl8jIRr6GNlRzAP19_igRbtK9YXa'
  },
  logRocketAppId: '82ifzt/prodgreytme',
  oneSignal: {
    appId: 'caef5a8a-8648-46b2-8288-f8f8ed5b637f',
    safari_web_id: 'web.onesignal.auto.07146831-e5cf-4801-bde7-1fd4b21ac045',
    allowLocalhostAsSecureOrigin: false
  }
};
