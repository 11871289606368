<div class="menu" *ngIf="!split">
  <div *ngFor="let item of menuItems"
        class="menu__item"
        [ngClass]="{
          'menu__item--disabled': item.disabled,
          'menu__item--active': activeMenuIndex === menuItems.indexOf(item),
          'menu__item--grid': item.icon
        }"
        (click)="didSelectItem(item)">
    <img class="menu__icon" [src]="item.icon" *ngIf="item.icon">
    {{ item.name | translate }}
    <div class="menu__badge" *ngIf="item.badge">
      <img *ngIf="item.badgeIcon" [src]="item.badgeIcon" alt="Badge icon">
      <span>{{ item.badge }}</span>
    </div>
  </div>
</div>

<div class="menu" *ngIf="split">
  <div class="menu__item"
      *ngFor="let item of visibleItems; let i = index"
      [ngClass]="{
        'menu__item--disabled': item.disabled,
        'menu__item--active': item.name === menuItems[activeMenuIndex].name,
        'menu__item--grid': item.icon
      }"
      (click)="didSelectItem(item)">
    <img class="menu__icon" [src]="item.icon" *ngIf="item.icon">
    {{ item.name }}
    <div class="menu__badge" *ngIf="item.badge">{{ item.badge }}</div>
  </div>

  <div class="menu__filter">
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon class="menu__filter-icon">more_vert</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <ng-container *ngFor="let item of dropdownItems">
        <button mat-menu-item
                (click)="didSelectItem(item)"
                [disabled]="item.disabled"
                [ngClass]="{
                  'menu__item--grid': item.icon,
                  'menu__item--disabled': item.disabled
                }">
          <img class="menu__icon menu__icon--black" [src]="item.icon" *ngIf="item.icon">
          <span>{{ item.name }}</span>
        </button>
      </ng-container>
    </mat-menu>
  </div>
</div>
