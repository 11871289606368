import { ComingSoon } from '../interfaces/coming-soon';

export const comingSoon: ComingSoon[] = [
  {
    img: 'assets/coming-soon/notifications.svg',
    text: 'Setup when you want to receive email notifications.',
    color: '#E3BE30'
  },
  {
    img: 'assets/coming-soon/privacy.svg',
    text: 'Setup criterias at which point creatives will see your works & detailed infos.',
    color: '#00C67E'
  },
  {
    img: 'assets/coming-soon/visibility.svg',
    text: 'See exactly what information we track from you and why.',
    color: '#F17E7E'
  }
];
