<div class="slider">
  <span class="digit" *ngIf="!hideDigits">-5</span>
<!--  <svg width="57" height="29" viewBox="0 0 57 29" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--    <path d="M56.9012 28.2479C56.9012 20.8249 53.9525 13.706 48.7036 8.45712C43.4548 3.20829 36.3359 0.259522 28.9129 0.259521C21.4899 0.259521 14.371 3.20828 9.12213 8.45712C3.8733 13.706 0.924531 20.8249 0.92453 28.2479H1.81668C1.81669 21.0615 4.67146 14.1695 9.75298 9.08797C14.8345 4.00644 21.7265 1.15168 28.9129 1.15168C36.0992 1.15168 42.9913 4.00645 48.0728 9.08797C53.1543 14.1695 56.0091 21.0615 56.0091 28.2479H56.9012Z" fill="#DDDDDD"/>-->
<!--  </svg>-->

  <svg width="85" height="42" viewBox="0 0 85 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M84.1339 41.9584C84.1339 30.8842 79.7347 20.2635 71.904 12.4328C64.0733 4.60212 53.4526 0.202882 42.3784 0.202881C31.3041 0.20288 20.6834 4.60211 12.8527 12.4328C5.02204 20.2635 0.622804 30.8842 0.622803 41.9584H1.9538C1.9538 31.2372 6.21281 20.955 13.7939 13.374C21.375 5.79288 31.6571 1.53388 42.3784 1.53388C53.0996 1.53388 63.3818 5.79288 70.9629 13.374C78.5439 20.955 82.8029 31.2372 82.8029 41.9584H84.1339Z" fill="#DDDDDD"/>
  </svg>

  <span class="digit" *ngIf="!hideDigits">+5</span>

  <span class="arrow" [ngStyle]="{transform: transform}">
    <svg width="15" height="32" viewBox="0 0 11 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.97264 21.4881L8.31622 9.71614C8.83876 9.30742 9.27358 8.79758 9.59468 8.21707C9.91577 7.63655 10.1166 6.99727 10.1851 6.33741C10.2536 5.67755 10.1884 5.01066 9.99339 4.37656C9.79839 3.74246 9.47759 3.15417 9.05014 2.64683C8.6227 2.13949 8.09738 1.72351 7.50557 1.42373C6.91376 1.12395 6.26761 0.94653 5.60569 0.902063C4.94378 0.857597 4.2797 0.946997 3.65311 1.16492C3.02652 1.38285 2.45029 1.72483 1.95882 2.17044C1.89555 2.2277 1.8336 2.28671 1.77298 2.34748C1.27961 2.84166 0.896719 3.43491 0.649618 4.08804C0.402518 4.74116 0.296826 5.43929 0.339531 6.13629C0.382236 6.83329 0.572363 7.51329 0.89735 8.13136C1.22234 8.74943 1.67479 9.29151 2.2248 9.72176L4.58967 21.4896C4.62172 21.6495 4.7082 21.7933 4.8344 21.8966C4.96059 22 5.11868 22.0563 5.28177 22.0562C5.44486 22.0561 5.60285 21.9994 5.72886 21.8959C5.85487 21.7923 5.94086 21.6481 5.97264 21.4881ZM3.55379 9.17658C3.51772 8.99735 3.41351 8.83911 3.26311 8.73516C2.64445 8.30806 2.17845 7.69445 1.9331 6.98385C1.68775 6.27325 1.67589 5.50284 1.89924 4.78502C2.12258 4.0672 2.56946 3.43953 3.17468 2.99358C3.77989 2.54763 4.51176 2.30674 5.26353 2.30605C6.01529 2.30536 6.74761 2.5449 7.35364 2.98974C7.95967 3.43457 8.40771 4.06142 8.63237 4.77883C8.85704 5.49624 8.84659 6.26667 8.60255 6.97772C8.3585 7.68877 7.89363 8.30324 7.27576 8.73147C7.12552 8.83563 7.02159 8.99406 6.9859 9.17335L5.27753 17.7547L3.55379 9.17658Z" [attr.fill]="arrowFill"/>
      <path d="M4.50386 6.54457C4.64425 6.6803 4.82174 6.77135 5.01388 6.80621C5.20602 6.84107 5.40418 6.81817 5.58331 6.7404C5.76243 6.66264 5.91447 6.5335 6.0202 6.36933C6.12594 6.20515 6.18061 6.01331 6.17731 5.81806C6.17402 5.62281 6.11289 5.43293 6.00168 5.27242C5.89046 5.11191 5.73415 4.98798 5.5525 4.91631C5.37085 4.84463 5.17203 4.82844 4.98118 4.86976C4.79032 4.91109 4.61601 5.00808 4.48028 5.14847C4.29854 5.33687 4.19894 5.58966 4.20336 5.8514C4.20778 6.11313 4.31585 6.36242 4.50386 6.54457ZM5.48423 5.53052C5.5444 5.58869 5.58597 5.66339 5.60368 5.74519C5.62139 5.82698 5.61445 5.91219 5.58373 5.99004C5.55302 6.06789 5.4999 6.13488 5.43111 6.18254C5.36232 6.23021 5.28094 6.2564 5.19726 6.25782C5.11359 6.25923 5.03137 6.2358 4.96101 6.19048C4.89065 6.14517 4.83531 6.08001 4.80198 6.00324C4.76865 5.92647 4.75883 5.84155 4.77377 5.7592C4.78871 5.67686 4.82773 5.60079 4.8859 5.54062C4.96391 5.45994 5.07077 5.41354 5.18298 5.41165C5.29518 5.40975 5.40355 5.45251 5.48423 5.53052Z" [attr.fill]="arrowFill"/>
    </svg>
  </span>
</div>
