<div class="advisor-card" [ngClass]="{'advisor-card--grey': isRemove}" *ngIf="isInUserActionMode">
  <div class="advisor-card__button-post-action">
    <h3 class="advisor-card__text" *ngIf="!isRemove">
      Creative <br> added to your <br><b>trusted advisor</b><br> list.
    </h3>
    <h3 class="advisor-card__text" *ngIf="isRemove">
      Creative <br> removed from your <br><b>trusted advisor</b><br> list.
    </h3>

    <div class="advisor-card__user">
      <app-user-info [userId]="advisorId"></app-user-info>
      <p class="advisor-card__user--badge" [style.color]="'#72D4BB'">
        €{{ adviser.advisorrate }}/h
      </p>
    </div>

    <h4 class="advisor-card__text" *ngIf="!isRemove">You will have quick access to this connector during your next upload.</h4>
    <h4 class="advisor-card__text my-8" *ngIf="isRemove">You will no longer see this connector in your trusted connector list.</h4>

    <p class="advisor-card__text" *ngIf="!isRemove">To receive feedback onto an uploaded project. Go to myprojects, select edit and then add this advisor.</p>

    <button class="advisor-card__button" (click)="close()" *ngIf="!isInUserActionInFlightMode">
      Got it
    </button>

    <button class="advisor-card__button" *ngIf="isInUserActionInFlightMode">
      <app-small-spinner diameter="20"></app-small-spinner>
    </button>
  </div>
</div>
