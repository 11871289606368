export class Avtrackface {

  constructor(
    public x?: number,
    public y?: number,
    public trackposition?: number,
    public color?: string,
    public comment?: string,
    public imagePath?: string,
    public avratingparam_id?: number,
    public order?: number,
    public uid?: string,
  ) { }
}
