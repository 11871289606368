import { HttpParams } from '@angular/common/http';

export function createHttpParams(params: {}): HttpParams {
  let httpParams: HttpParams = new HttpParams();
  Object.keys(params).forEach((param: string) => {
    if (param && params[param] != null) {
      httpParams = httpParams.set(param, params[param]);
    }
  });
  return httpParams;
}
