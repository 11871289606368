import { Country } from '../interfaces/countries';

export const CountriesItems: Country[] = [
  {
    name: 'Argentina',
    code: 'AR'
  },
  {
    name: 'Australia',
    code: 'AU'
  },
  {
    name: 'Austria',
    code: 'AT'
  },
  {
    name: 'Belgium',
    code: 'BE'
  },
  {
    name: 'Bolivia',
    code: 'BO'
  },
  {
    name: 'Bulgaria',
    code: 'BG'
  },
  {
    name: 'Canada',
    code: 'CA'
  },
  {
    name: 'Costa Rica',
    code: 'CR'
  },
  {
    name: 'Croatia*',
    code: 'HR'
  },
  {
    name: 'Cyprus',
    code: 'CY'
  },
  {
    name: 'Czech Republic*',
    code: 'CZ'
  },
  {
    name: 'Denmark',
    code: 'DK'
  },
  {
    name: 'Dominican Republic',
    code: 'DO'
  },
  {
    name: 'Egypt',
    code: 'EG'
  },
  {
    name: 'Finland',
    code: 'FI'
  },
  {
    name: 'France',
    code: 'FR'
  },
  {
    name: 'Germany',
    code: 'DE'
  },
  {
    name: 'Greece',
    code: 'GR'
  },
  {
    name: 'Hong Kong',
    code: 'HK'
  },
  {
    name: 'Hungary',
    code: 'HU'
  },
  {
    name: 'Iceland*',
    code: 'IS'
  },
  {
    name: 'India',
    code: 'IN'
  },
  {
    name: 'Indonesia',
    code: 'ID'
  },
  {
    name: 'Ireland',
    code: 'IE'
  },
  {
    name: 'Israel',
    code: 'IL'
  },
  {
    name: 'Italy',
    code: 'IT'
  },
  {
    name: 'Latvia',
    code: 'LY'
  },
  {
    name: 'Liechtenstein*',
    code: 'LI'
  },
  {
    name: 'Lithuania',
    code: 'LT'
  },
  {
    name: 'Luxembourg',
    code: 'LU'
  },
  {
    name: 'Malta',
    code: 'MT'
  },
  {
    name: 'Mexico',
    code: 'MX'
  },
  {
    name: 'Netherlands',
    code: 'NL'
  },
  {
    name: 'New Zealand',
    code: 'NZ'
  },
  {
    name: 'Norway',
    code: 'NO'
  },
  {
    name: 'Peru',
    code: 'PE'
  },
  {
    name: 'Poland',
    code: 'PL'
  },
  {
    name: 'Portugal',
    code: 'PT'
  },
  {
    name: 'Romania',
    code: 'RO'
  },
  {
    name: 'Singapore',
    code: 'SG'
  },
  {
    name: 'Slovakia',
    code: 'SK'
  },
  {
    name: 'Slovenia',
    code: 'SI'
  },
  {
    name: 'Spain',
    code: 'ES'
  },
  {
    name: 'Sweden',
    code: 'SE'
  },
  {
    name: 'Switzerland',
    code: 'CH'
  },
  {
    name: 'Thailand',
    code: 'TH'
  },
  {
    name: 'Trinidad & Tobago',
    code: 'TT'
  },
  {
    name: 'United Kingdom',
    code: 'UK'
  },
  {
    name: 'Uruguay',
    code: 'UY'
  },
  {
    name: 'Brazil',
    code: 'BR'
  },
  {
    name: 'Chile',
    code: 'CL'
  },
  {
    name: 'Colombia',
    code: 'CO'
  },
  {
    name: 'Paraguay',
    code: 'PY'
  }
];
