import { User } from './user.model';

export interface AudioMessage {
  url: string;
  duration?: number;
  language?: string;
}

export interface MessageScreenshot {
  url?: string;
  file?: File;
}

export class Message {
  constructor(
    public conversation_id?: number,
    public user_id?: number,
    public body?: string,
    public audio_message?: AudioMessage,
    public special?: string,
    public user?: User,
    public read?: boolean,
    public message_payment_session_id?: number,
    public created_at?: string,
    public id?: number,
    public feedback_id?: number,
    public paymentrequestdenied?: boolean,
    public paymentrequestamount?: number,
    public paymentrequesttime?: number,
    public paymentrequestapproved?: boolean,
    public message_screenshots?: MessageScreenshot[],
    public message_screenshots_attributes?: MessageScreenshot[],
    public initial_message_id?: number,
    public system_message?: boolean,
    public replay_message?: Message,
    public icebreaker_question_id?: number
  ) { }
}
