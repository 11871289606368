import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { avatarsImages } from 'src/app/pages/usersettings/constants';
import { User } from '../../models/user.model';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {
  @Input() private userId: number;
  @Input() private user: User;

  @Input() public noName: boolean;
  @Input() public isWhite: boolean;
  @Input() public isGrey = false;
  @Input() public isRight: boolean;
  @Input() public isRound: boolean;
  @Input() public noBorder = true;
  @Input() public disableLink: boolean;

  @Input() public borderWidth = 3;
  @Input() public borderColor = '#E9E9E9';

  public loadedUser: User;
  public isAnon: boolean;

  private avatars = avatarsImages;
  public avatar: string;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.isAnon = this.authService.isAnon(this.userId || this.user.id);

    if (this.isAnon) {
      this.getAvatar();
      return;
    }

    if (this.user) {
      this.loadedUser = this.user;
      this.getAvatar();
      return;
    }

    this.authService.fetchUser(this.userId).subscribe(res => {
      this.loadedUser = res;
      this.getAvatar();
    });
  }

  private getAvatar() {
    if (this.isAnon) {
      this.avatar = 'assets/anon.png';
      return;
    }

    if (this.loadedUser.image) {
      this.avatar = this.loadedUser.image;
      return;
    }

    this.avatar = this.avatars[this.loadedUser.id % this.avatars.length];
  }
}
