import { Injectable } from '@angular/core';
import { ActionCableService, Channel } from 'angular2-actioncable';
import { BehaviorSubject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MessagesService } from './messages.service';
import { MessageNotificationComponent } from '../shared/components/message-notification/message-notification.component';
import { ToastrService } from 'ngx-toastr';
import { MessageWs } from '../shared/models/message-ws';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  messageChannel: Channel;
  newMessage$ = new BehaviorSubject(null);

  meetCounterChannel: Channel;
  meetCounter$ = new BehaviorSubject(null);

  sessionChannel: Channel;
  newSession$ = new BehaviorSubject(null);

  public newPaidFeedbackRequest$ = new BehaviorSubject(false);
  public currentConversationId$ = new BehaviorSubject(null);

  private get channelParams(): { accessToken: string; client: string, uid: string } {
    const accessToken = localStorage.getItem('accessToken');
    const client = localStorage.getItem('client');
    const uid = localStorage.getItem('uid');

    return {accessToken, client, uid};
  }

  constructor(
    private cableService: ActionCableService,
    private messageService: MessagesService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  subscribeToSessions(conversationId: number) {
    const {accessToken, client, uid} = this.channelParams;

    this.sessionChannel = this.cableService
      .cable(`${environment.wsBase}?access-token=${accessToken}&client=${client}&uid=${uid}`)
      .channel('MessageSessionChannel', {room: 'room_session_' + conversationId});

    this.sessionChannel.received().pipe(
      tap(session => this.newSession$.next(session)),
    ).subscribe();
  }

  subscribeToMessages(userId) {
    const {accessToken, client, uid} = this.channelParams;

    this.messageChannel = this.cableService
      .cable(`${environment.wsBase}?access-token=${accessToken}&client=${client}&uid=${uid}`)
      .channel('MessageChannel', {room: 'room_' + userId});

    this.messageChannel.received().pipe(
      tap(message => this.newMessage$.next(message)),
      filter(message => !message.updated),
      tap((message: MessageWs) => {
        this.messageService.unreadMessages$.next(this.messageService.unreadMessages$.value + 1);
        this.playAudio(message);
        this.showMessageToastr(message);
      })
    ).subscribe();
  }

  private playAudio(message: MessageWs) {
    const audio = new Audio();
    audio.oncanplaythrough = () => audio.play();

    if (document.hasFocus() && message.conversation_id === +this.currentConversationId$.value) {
      return;
    }

    switch (message.special) {
      case 'paid_feedback_request':
        this.newPaidFeedbackRequest$.next(true);
        audio.src = 'assets/audio/audio_3.mp3';
        break;
      case 'instant_feedback_request':
        audio.src = 'assets/audio/audio_2.mp3';
        break;
      default:
        audio.src = 'assets/audio/audio_4.wav';
        break;
    }

    audio.load();
  }

  private showMessageToastr(message: MessageWs) {
    const currentRoute = this.router.url;
    if (!currentRoute.includes(`conversations/${message.conversation_id}`)) {
      this.toastr.clear(); // clear all previous toasts
      this.toastr.show(undefined, undefined, {
        tapToDismiss: false,
        toastComponent: MessageNotificationComponent,
        enableHtml: false,
        positionClass: 'toast-bottom-left',
        disableTimeOut: true,
        payload: message
      });
    }
  }

  unsubscribeFromMessages() {
    this.messageChannel?.unsubscribe();
  }

  unsubscribeFromSessions() {
    this.sessionChannel?.unsubscribe();
  }

  subscribeMeetingCounter(conversationId: number) {
    const {accessToken, client, uid} = this.channelParams;

    this.meetCounterChannel = this.cableService
      .cable(`${environment.wsBase}?access-token=${accessToken}&client=${client}&uid=${uid}`)
      .channel('MeetingChannel', {room: 'conversation_' + conversationId});

    this.meetCounterChannel.received().pipe(
      tap(message => {
        this.meetCounter$.next(message);
      }),
    ).subscribe();
  }

  sendMeetingCounter(conversationId: number, isActive: boolean, seconds?: number) {
    if (isActive) {
      this.meetCounterChannel.send({
        conversation_id: conversationId,
        active: isActive,
        seconds
      });
    } else {
      this.meetCounterChannel.send({
        active: isActive
      });
    }
  }

  unsubscribeFromMeetingCounter() {
    this.meetCounterChannel?.unsubscribe();
  }

  closeConnection(): void {
    const {accessToken, client, uid} = this.channelParams;

    this.cableService.disconnect(`${environment.wsBase}?access-token=${accessToken}&client=${client}&uid=${uid}`);
  }
}

