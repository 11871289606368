import { TranslateService } from '@ngx-translate/core';
import { Injector } from '@angular/core';
import { LOCATION_INITIALIZED } from '@angular/common';

export function TranslationConfigInitialize(
  translate: TranslateService, injector: Injector) {
  return async () => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

    const defaultLang = 'en';
    translate.addLangs(['en', 'ge']);
    translate.setDefaultLang(defaultLang);
    try {
      await translate.use(defaultLang).toPromise();
    } catch (err) {
      console.log(err);
    }
  };
}
