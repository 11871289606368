export const LanguagesList : string[] = [
  'English',
  'Russian',
  'Spanish',
  'Portuguese',
  'French',
  'German',
  'Italian',
  'Chinese',
  'Polish',
  'Korean',
  'Turkish',
  'Hindi',
  'Japanese',
  'Czech',
  'Hungarian',
  'Ukrainian',
  'Dutch',
  'Swedish',
  'Danish',
  'Greek',
  'Thai',
  'Norwegian',
  'Catalan',
  'Flemish',
  'Arabic',
  'Mandarin',
  'Malay',
  'Persian',
  'Bangla',
];
