import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MetaService } from '@ngx-meta/core';
import { MatDialog } from '@angular/material/dialog';
import { SignUpMessageComponent } from 'src/app/shared/components/sign-up-message/sign-up-message.component';
import { takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { AuthService } from 'src/app/auth/auth.service';
import { RateflowService } from 'src/app/services/rateflow.service';
import { User } from 'src/app/shared/models/user.model';
import { Skill } from '../../../shared/models/skill.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SeoService } from '../../../services/seo.service';
import { ShareProfileModalComponent } from '../../../shared/components/share-profile-modal/share-profile-modal.component';
import { getUserSeoData } from '../../../shared/functions/get-user-seo-data';
import { UserSkill } from '../../../shared/models/UserSkill.model';
import { BreakpointObserver } from '@angular/cdk/layout';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profile-info-card',
  templateUrl: './profile-info-card.component.html',
  styleUrls: ['./profile-info-card.component.scss']
})
export class ProfileInfoCardComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('buttonElement', {static: false}) buttonElement: ElementRef;

  @Input() isContainedMode = false;
  @Input() user: User;
  @Input() currentUser: User;
  @Input() pickedSkill: UserSkill;
  aboutMeForm = new UntypedFormGroup({
    title: new UntypedFormControl('', [Validators.minLength(50)]),
    description: new UntypedFormControl('', [Validators.minLength(500)]),
  });

  @Output() selectedSkill: EventEmitter<Skill> = new EventEmitter<Skill>();

  urlIsCopied: boolean;
  referralLink: string;
  isExpand = false;

  constructor(
    public authService: AuthService,
    private rateflowService: RateflowService,
    private readonly metaService: MetaService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private readonly translationService: TranslateService
  ) {
    super();
    this.translationService.store.onLangChange
      .pipe(takeUntil(this.destroyed))
      .subscribe((lang: LangChangeEvent) => {
        this.translationService.use(lang.lang);
      });
  }

  ngOnInit(): void {
    const originUrl = window.location.origin;
    this.referralLink = `https://getme.global/profile/` + this.user.sharetoken;

    this.authService.userSubject$.pipe(
      tap(user => {
        this.currentUser = user;
        if (this.user?.id !== this.currentUser?.id) {
          this.aboutMeForm.patchValue(this.user.about);
          this.aboutMeForm.disable();
          return;
        }
        this.aboutMeForm.enable();
        this.aboutMeForm.patchValue(user.about);
      }),
      takeUntil(this.destroyed),
    ).subscribe();

    this.breakpointObserver.observe('(max-width: 600px)')
      .pipe(takeUntil(this.destroyed))
      .subscribe(res => {
        this.isExpand = !res.matches;
      });
    this.setMetaTags();
  }

  selectSkill(skill: UserSkill): void {
    this.selectedSkill.emit(skill);
  }

  updateUserInfo(): void {
    const {title, description} = this.aboutMeForm.value;
    if ((title.length || description.length) && this.aboutMeForm.dirty) {
      this.authService.updateUser({...this.user, about: {title, description}})
        .pipe(takeUntil(this.destroyed))
        .subscribe();
    }
  }

  private setMetaTags(): void {
    const {title, description, image, url} = getUserSeoData(this.user);
    this.metaService.setTitle(title);
    this.metaService.setTag('og:description', description);
    this.metaService.setTag('og:url', url);
    this.metaService.setTag('og:image', image);

    this.metaService.setTag('twitter:title', title);
    this.metaService.setTag('twitter:description', description);
    this.metaService.setTag('twitter:url', url);
    this.metaService.setTag('twitter:image', image);
  }

  addToAdvisorsDidClick() {
    if (!this.authService.userIsSignedIn()) {
      this.dialog.open(SignUpMessageComponent, {
        maxWidth: '350px',
        width: '100vw',
        maxHeight: '510px',
        height: '100vh',
        autoFocus: false,
        panelClass: 'sign-up-message-modal',
        data: 'Adding Connectors is only possible for registered creatives.'
      });

      return;
    }

    if (this.currentUser.adviserIDs.includes(this.user.id)) {
      this.currentUser.adviserIDs = this.currentUser.adviserIDs.filter(obj => obj !== this.user.id);

      this.rateflowService.removeAdviserFromFavorites(this.user.id, this.currentUser.id).subscribe();
      return;
    }

    this.rateflowService.addAdviserToFavorites(this.user.id, this.currentUser.id).subscribe(() => {
      this.currentUser.adviserIDs.push(this.user.id);
    });
  }

  copyUrl() {
    this.urlIsCopied = true;
  }

  shareProfile(): void {
    this.dialog.open(ShareProfileModalComponent, {
      maxWidth: 877,
      width: '100%',
      data: this.user
    });
  }

  expand(): void {
    this.isExpand = !this.isExpand;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.metaService.setTitle('Give Feedback');
    this.metaService.setTag('description', 'Join now to improve your skills');
    this.metaService.setTag('og:url', 'https://getme.global');
    // this.metaService.setTag('og:image', 'https://efwfileupload.s3-accelerate.amazonaws.com/favi/fbimage.png');
  }
}
