import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../../../../models/user.model';
import { avatarsImages } from '../../../../../pages/usersettings/constants';

@Pipe({
  name: 'userAvatar'
})
export class UserAvatarPipe implements PipeTransform {
  private avatars = avatarsImages;

  transform(user: User): string {
    if (user.image) {
      return user.image;
    }
     return this.avatars[user.id % this.avatars.length];
  }

}
